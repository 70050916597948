import { withTranslation, Trans } from 'react-i18next';
import React, { useContext, useEffect } from "react";
import { AppContext } from '../../utils/context';
import { MainLayout, Top, Center, Bottom } from '../../layouts/MainLayout/MainLayout';
import { Drawer, MainCard, MainTitle, MainVideo } from '../../components';
import { navigate } from '@reach/router';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as sdk from '../../actions/sdk';


const ExitPage = (props) => {
    const { t, i18n } = props;
    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    var toastText;
    const notify = () => toast(toastText);

    useEffect(() => {
        dispatch({ type: "userJourney", payload: 'exit-page' })
        let isApiSubscribed = true;
        sdk.antennaStop()
            .then(() => sdk.motionIdle())
            .then(() => sdk.motionOpen())
            .then(() => {
                if (isApiSubscribed)
                    navigate(process.env.PUBLIC_URL + '/timer/remove-device', { replace: true })
            })
            .catch(function (error) {
                console.log(error);
                if (process.env.REACT_APP_DEBUG === '1')
                    notify();
            });
        return () => {
            isApiSubscribed = false;
        }
    }, []);


    return (
        <>
            <Drawer open_device />
        </>
    );
}

export default withTranslation()(ExitPage);
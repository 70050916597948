import { withTranslation, Trans } from 'react-i18next';
import React, { useContext, useEffect } from "react";
import { AppContext } from '../../utils/context';
import { MainLayout, Top, Center, Bottom } from '../../layouts/MainLayout/MainLayout';
import { navigate } from '@reach/router';
import axios from 'axios';
import { MainImage, MainVideo } from '../../components';

const IdentificationSuccess = (props) => {
    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;

    // useEffect(() => {
    //     dispatch({ type: "userJourney", payload: 'identification-success' })
    //     if (state.sessionInfo.flow === 'trade_in') {
    //         axios.get(`https://server.greenpanda.io/scripts/getWebassessment.php?uid=${state.sessionInfo.uuid}`, { 'Content-Type': 'application/x-www-form-urlencoded' })
    //             .then((res) => {
    //                 console.log(res.data);
    //                 dispatch({ type: "webappInfo", payload: res.data })
    //             })
    //             .catch(function (error) {
    //                 console.log(error);
    //                 dispatch({ type: "webappInfo", payload: {} })
    //             });
    //     }
    //     setTimeout(() => {
    //         navigate(process.env.PUBLIC_URL + "/identification", { replace: true })
    //     }, 3000);
    // }, [])
    useEffect(() => {
        dispatch({ type: "userJourney", payload: 'identification-success' })
        let isApiSubscribed = true;
        const timeout = setTimeout(() => {
            axios.post(process.env.REACT_APP_IMEI_SERVICE_URL, {
                "imei": state.deviceInfo.imei
            }).then((res) => {
                if (isApiSubscribed) {
                    dispatch({ type: "deviceInfo", payload: res.data })
                    var imsiCheck = state.deviceInfo.imsi.slice(0, 5);
                    if ((res.data.fmiOn || res.data.fmiON) && imsiCheck !== '11199') {
                        navigate(process.env.PUBLIC_URL + "/identification", { replace: true })
                        dispatch({ type: 'fmi_sim', payload: { fmi_sim: true } })
                        console.log('fmi-sim')
                    }
                    else if ((res.data.fmiOn || res.data.fmiON) && imsiCheck === '11199') {
                        navigate(process.env.PUBLIC_URL + "/identification", { replace: true })
                        dispatch({ type: 'fmi_sim', payload: { fmi: true } })
                        console.log('fmi')
                    }
                    else if (!res.data.fmiOn && !res.data.fmiON && imsiCheck !== '11199') {
                        navigate(process.env.PUBLIC_URL + "/identification", { replace: true })
                        dispatch({ type: 'fmi_sim', payload: { sim: true } })
                        console.log('sim')
                    }
                    else {
                        navigate(process.env.PUBLIC_URL + "/identification", { replace: true })
                        console.log('payment')
                    }
                    dispatch({ type: "sessionInfo", payload: { identification: 1 } })
                }
            })
                .catch(function (error) {
                    console.log(error);
                    navigate(process.env.PUBLIC_URL + '/price-not-found', { replace: true })
                });
        }, 3000);
        return () => {
            clearTimeout(timeout);
        }
        // }
        return () => {
            isApiSubscribed = false;
        };
    }, [])

    return (
        <>
            <MainLayout
                noLogo
                exit
            // bg={`url(images/${process.env.REACT_APP_THEME}/IdentificationSuccess/bg.png)`}
            >
                <Top></Top>
                <Center>
                    <div className='d-flex align-items-center flex-column' style={{ height: '65vh' }}>
                        <MainVideo className='mb-5 pb-5' video='IdentificationSuccess/identification_success.mp4' />
                        <div className='fs48 fw600 text-center'><Trans i18nKey="identification_success" /> 🎉</div>
                    </div>
                </Center>
                <Bottom>
                </Bottom>
            </MainLayout>
        </>
    );
}

export default withTranslation()(IdentificationSuccess);
import React from "react";
import styled from "styled-components";

const Card = styled.div`
    position: relative;
    width: ${props => !props.width ? '90vw' : props.width};
    height: ${props => !props.height ? (!props.large ? '48vh' : '65vh') : props.height};
    background: ${props => !props.bg ? '#FFFFFF' : props.bg};
    color: ${({ theme: { colors } }) => colors.fonts};
    border-radius: 0.8rem;
    border: 2px solid ${props => + !props.border ? '#E6E6E6' : props.border};
    display: flex;
    justify-content: center;
    align-items: center;
`;

const MainCard = (props) => {
    return (
        <Card onClick={props.onClick}
            className={props.className}
            large={props.large}
            width={props.width}
            height={props.height}
            bg={props.bg}
            border={props.border}
        >
            {props.children}
            {props.notification}
        </Card >
    );
}

export default MainCard;
import { withTranslation, Trans } from 'react-i18next';
import React, { useState, useContext, useEffect } from "react";
import { AppContext } from '../../utils/context';
import { Popup } from '../../components';
import ReactStars from "react-rating-stars-component";
import styled from "styled-components";
import ActionButton from '../ActionButton/ActionButton';
import { Webhook } from '../../actions/webhook';

const Button = styled.div`
    background:${props => props.isActive ? '#BBFFCC' : '#F2F2F2'};
    padding: 2.5vh 4.5vw;
    border-radius: 0.8rem;
    font-size: 3rem;
    font-weight:600;
`;

const RateExperience = (props) => {
    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    const [rating, setRating] = useState('');
    const [ratingOptions, setRatingOptions] = useState(false);
    const secondExample = {
        size: 60,
        count: 5,
        color: "black",
        activeColor: "red",
        value: 0,
        a11y: false,
        isHalf: false,
        emptyIcon: <div className='mx-5'><img src={`./images/${process.env.REACT_APP_THEME}/RateExperience/star.svg`} /></div>,
        filledIcon: <div className='mx-5'><img src={`./images/${process.env.REACT_APP_THEME}/RateExperience/filled_star.svg`} /></div>,
        onChange: (newValue) => {
            console.log(` new value is ${newValue}`);
            setRating(newValue);
        }
    };
    const [isActive, setIsActive] = useState({});

    const handleBtn = btnId => e => {
        e.preventDefault();
        setIsActive(state => ({
            ...state,
            [btnId]: !state[btnId]
        }));
        setRatingOptions(true);
    };
    useEffect(() => {
        if (isActive && rating) {
            console.log(isActive)
            dispatch({ type: "rating", payload: { rating: rating, rating_options: isActive } })
        }
    }, [isActive, rating])
    return (
        <Popup className="rate" show={props.show} onHide={props.onHide}>
            <div className='center flex-column mb-5'>
                <div className='fs30 fw600 mb-5'><Trans i18nKey="rate_experience.title" /></div>
                <ReactStars  {...secondExample} />
                <div className='fs30 fw600 my-5 pt-5'><Trans i18nKey="rate_experience.subtitle" /></div>

                <div className='center'>
                    <Button isActive={isActive["price"]} onClick={handleBtn("price")} className='mx-4'><Trans i18nKey="rate_experience.price" /></Button>
                    <Button isActive={isActive["speed"]} onClick={handleBtn("speed")} className='mx-4'><Trans i18nKey="rate_experience.speed" /></Button>
                    <Button isActive={isActive["cool"]} onClick={handleBtn("cool")} className='mx-4'><Trans i18nKey="rate_experience.cool" /></Button>
                </div>
                <div className='center my-5 pb-5'>
                    <Button isActive={isActive["convenience"]} onClick={handleBtn("convenience")} className='mx-4'><Trans i18nKey="rate_experience.convenience" /></Button>
                    <Button isActive={isActive["kiosk_proximity"]} onClick={handleBtn("kiosk_proximity")} className='mx-4'><Trans i18nKey="rate_experience.kiosk_proximity" /></Button>
                </div>
                <ActionButton disabled={rating === '' || !ratingOptions} onClick={props.onSubmit} style={{ width: '26vw' }}><Trans i18nKey="rate_experience.submit" /></ActionButton>
            </div>
        </Popup>
    );
}

export default withTranslation()(RateExperience);
import React, { useEffect, useState, useContext } from 'react';
import { navigate } from "@reach/router"
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import IdlePopup from '../components/IdlePopup/IdlePopup';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;




export const SHOW_MODAL_THRESHOLD = 30;

const Header = ({ children, location: { pathname }, storeId, t, i18n }) => {
  if (pathname === process.env.PUBLIC_URL + '/timer/fmi-info' ||
    pathname === process.env.PUBLIC_URL + '/timer/insert-complete' ||
    pathname === process.env.PUBLIC_URL + '/timer/remove-device' ||
    pathname === process.env.PUBLIC_URL + '/timer/get-sim' ||
    pathname === process.env.PUBLIC_URL + '/timer/insert-sim' ||
    pathname === process.env.PUBLIC_URL + '/timer/thank-you-page')
    var INACTIVE_TIMEOUT = 200;
  else
    var INACTIVE_TIMEOUT = 60;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [forceChange, triggerForceChange] = useState(false);
  const [counter, setCounter] = useState(INACTIVE_TIMEOUT);
  const [show, setShow] = useState(false);


  let timer;

  const restartTimer = () => {
    if (timer) clearInterval(timer);
    setShow(true);
    setCounter(INACTIVE_TIMEOUT);
    setIsModalVisible(false);
    triggerForceChange(!forceChange);
  };

  const endTimer = () => {
    navigate(process.env.PUBLIC_URL + '/', { replace: true });
  };

  useEffect(() => {
    restartTimer()
  }, [pathname]);

  useEffect(() => {
    return () => {
      if (timer) clearInterval(timer);
    }
  }, [])

  useEffect(() => {
    timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000)

    if (counter <= SHOW_MODAL_THRESHOLD && !isModalVisible) {
      setIsModalVisible(true);
    }

    if (!counter && pathname !== `/${storeId}` && pathname !== `/${storeId}/thank-you`) {
      endTimer();
    }

    return () => clearInterval(timer);
  }, [counter, forceChange]);


  return (
    <Container onClick={restartTimer}>
      {isModalVisible && (
        <IdlePopup counter={counter < 10 ? `0${counter}` : `${counter}`} show={show} onHide={() => setShow(false)} />
      )}
      <div style={{ 'width': '100vw', 'height': '100vh' }}>
        {children}
      </div>
    </Container>
  )
}

export default withTranslation()(Header);

import React from "react";
import styled from "styled-components";
import InfoIcon from './info.svg';
import NeedleIcon from './needle.svg';


const Popup = styled.div`
    position: absolute; 
    top: -100%;
    left: 50%;
    transition: all 1s;
    background: none;
    border-radius: 16px;
    transform: translate(-50%, -50%);
    width: fit-content;
}


  `;

const Inner = styled.div`
    height:8vh;
    max-height: 90px;
    display: flex;
    align-items: center;
    padding: 0 40px;
    background: #E5FFEC;
    border-radius: 16px;
    font-size: 2rem;
    font-weight: 400;
    line-height: 3rem;
`;

const Notification = (props) => {
    return (
        <Popup style={props.loading ? {} : { top: "-3%" }}>
            <Inner>{<img className='me-5' src={props.needle ? NeedleIcon : InfoIcon} />}{props.children}</Inner>
        </Popup>
    );
}

export default Notification;
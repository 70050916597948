import React from "react";

const MainVideo = (props) => {
    return (
        <div style={props.style} className={props.className} onClick={props.onClick}>
            {!props.loop ? <video style={{ width: props.width, maxHeight: props.height }} autoPlay muted>  <source src={`images/${process.env.REACT_APP_THEME}/${props.video}`} type="video/mp4" /></video>
                : <video autoPlay muted loop style={{ width: props.width, maxHeight: props.height, boxShadow: !props.boxShadow ? 'none' : '0px 4px 16px 0px rgba(0, 0, 0, 0.1)' }}>  <source src={`images/${process.env.REACT_APP_THEME}/${props.video}`} type="video/mp4" /></video>}
        </div>
    );
}

export default MainVideo;
import { withTranslation, Trans } from 'react-i18next';
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from '../../utils/context';
import { MainLayout, Center, BottomLeft, Top } from '../../layouts/MainLayout/MainLayout';
import { ExitPopup, ImageSlider } from '../../components';
import { navigate } from '@reach/router';
import axios from 'axios';
import io from "socket.io-client";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { uploadToS3 } from '../../actions/uploadImages';
import * as sdk from '../../actions/sdk';
import { test } from '../../actions/convertImage';

const Inspection = (props) => {
    const { t, i18n } = props;
    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    const [imei, setImei] = useState('');
    const [imsi, setImsi] = useState('');
    const [show, setShow] = useState(false);

    var toastText;
    const notify = () => toast(toastText);

    useEffect(() => {
        const timeout = setTimeout(() => {
            navigate(process.env.PUBLIC_URL + '/identification-failed', { replace: true })
        }, 120000);
        return () => {
            clearTimeout(timeout);
        }
    }, [])
    useEffect(() => {
        let now = new Date()
        const curdate = now.toISOString().substring(0, 10);
        const header = { 'Content-Type': 'Access-Control-Allow-Origin' }
        dispatch({ type: "userJourney", payload: 'inspection' })
        let isApiSubscribed = true;

        sdk.motionCamera()
            .then(() => sdk.cameraCaptureAll())
            .then((response) => {
                if (isApiSubscribed) {
                    var photos = [];
                    response.data.forEach((element, index) => {
                        photos.push(`https://grading-photos.s3.amazonaws.com/${curdate}/${state.sessionInfo.uuid}_${index}.jpg`);
                        var photoContent = element.photo;
                        uploadToS3(test(photoContent), state.sessionInfo.uuid + '_' + index);
                    });
                    dispatch({ type: "deviceInfo", payload: { photos: photos } })
                    return sdk.motionFaraday();
                }
            })
            .then(() => { if (isApiSubscribed) sdk.antennaStart() })
            .then(() => {
                if (isApiSubscribed) {
                    const socket = io(`${process.env.REACT_APP_SOCKET_URL}`, { path: '/sdk/socket.io' });
                    socket.on('connection', (conn) => {
                        console.log("connected")
                    })
                    socket.on('error', (error) => {
                        console.log('error')
                        console.log(error)
                    })
                    socket.on('connect_error', (error) => {
                        console.log('connect_error')
                        console.log(error)
                    })
                    socket.on("imei", (data) => {
                        setImei(data.imei)
                        setImsi(data.imsi)
                        dispatch({ type: "deviceInfo", payload: { imei: data.imei, imsi: data.imsi } })
                        navigate(process.env.PUBLIC_URL + "/identification-success", { replace: true })
                        socket.destroy();
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                toastText = <div className='fs24 text-center'>{error}</div>
                if (process.env.REACT_APP_DEBUG === '1')
                    notify();
            });
        return () => {
            isApiSubscribed = false;
        };
    }, [])

    // useEffect(() => {
    //     let isApiSubscribed = true;
    //     if (imei !== '' && imsi !== '') {
    //         console.log(imei)
    //         axios.post(process.env.REACT_APP_IMEI_SERVICE_URL, {
    //             "imei": imei
    //         }).then((res) => {
    //             if (isApiSubscribed) {
    //                 dispatch({ type: "deviceInfo", payload: res.data })
    //                 const timeout = setTimeout(() => {
    //                     var imsiCheck = imsi.slice(0, 5);
    //                     if ((res.data.fmiOn || res.data.fmiON) && imsiCheck !== '11199') {
    //                         navigate(process.env.PUBLIC_URL + "/identification-success", { replace: true })
    //                         dispatch({ type: 'fmi_sim', payload: { fmi_sim: true } })
    //                         console.log('fmi-sim')
    //                     }
    //                     else if ((res.data.fmiOn || res.data.fmiON) && imsiCheck === '11199') {
    //                         navigate(process.env.PUBLIC_URL + "/identification-success", { replace: true })
    //                         dispatch({ type: 'fmi_sim', payload: { fmi: true } })
    //                         console.log('fmi')
    //                     }
    //                     else if (!res.data.fmiOn && !res.data.fmiON && imsiCheck !== '11199') {
    //                         navigate(process.env.PUBLIC_URL + "/identification-success", { replace: true })
    //                         dispatch({ type: 'fmi_sim', payload: { sim: true } })
    //                         console.log('sim')
    //                     }
    //                     else {
    //                         navigate(process.env.PUBLIC_URL + "/identification-success", { replace: true })
    //                         console.log('payment')
    //                     }
    //                     dispatch({ type: "sessionInfo", payload: { identification: 1 } })
    //                 }, 100);
    //                 return () => {
    //                     clearTimeout(timeout);
    //                 }
    //             }
    //         })
    //             .catch(function (error) {
    //                 console.log(error);
    //                 navigate(process.env.PUBLIC_URL + '/timer/price-not-found', { replace: true })
    //             });
    //     }
    //     return () => {
    //         isApiSubscribed = false;
    //     };
    // }, [imei, imsi])


    return (
        <>
            <MainLayout noLogo>
                <Top>
                    <ImageSlider />
                </Top>
                <Center>
                    {!state.deviceInfo.photos && <>
                        <div className='my-5 fs30 fw600'><Trans i18nKey="inspection.evaluating" /></div>
                    </>}
                    {state.deviceInfo.photos && <>
                        <div className='my-5 fs30 fw600'><Trans i18nKey="inspection.identifying" /></div>
                    </>}
                    <div className='d-flex'>
                        {!state.deviceInfo.photos ? <div className="loader-line-evaluating"></div> :
                            <div className="completed-line"></div>}
                        {state.deviceInfo.photos ?
                            <div className="loader-line-identifying mx-4"></div> :
                            <div className="empty-line mx-4"></div>}
                        <div className="empty-line"></div>
                    </div>
                    <div className='d-flex justify-content-center mt-5'>
                        <div onClick={() => setShow(true)} className='fs30 px-5 py-4' style={{ border: '2px solid #F2F2F2', borderRadius: '6.5rem' }}><Trans i18nKey="inspection.cancel" /></div>
                    </div>
                </Center>
                <BottomLeft>
                    <ToastContainer position='bottom-left' />
                </BottomLeft>
            </MainLayout>
            <ExitPopup show={show} onHide={() => setShow(false)} />
        </>
    );
}

export default withTranslation()(Inspection);

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Trans } from 'react-i18next';

const Slide1 = styled.div`
    background-image:url(images/${process.env.REACT_APP_THEME}/Inspection/bg1.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 68vh;
    padding: 7vh 6vw;
    -webkit-animation: myfirst 5s ; /* Chrome, Safari, Opera */
    animation: myfirst 5s ;
`;
const Slide2 = styled.div`
    background-image:url(images/${process.env.REACT_APP_THEME}/Inspection/bg2.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 68vh;
    color: #FFFFFF;
    padding: 7vh 6vw;
    -webkit-animation: myfirst 5s ; /* Chrome, Safari, Opera */
    animation: myfirst 5s ;
`;

const Slide3 = styled.div`
    background-image:url(images/${process.env.REACT_APP_THEME}/Inspection/bg3.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 68vh;
    position:relative;
    padding: 7vh 6vw;
    -webkit-animation: myfirst 5s ; /* Chrome, Safari, Opera */
    animation: myfirst 5s ;
`;



const imageArray = [
    <Slide1>
        <div style={{ float: 'right' }}>
            <div className="fontThicker fs80 fw800"><Trans i18nKey="image_slider.title1" /></div>
            <div style={{ width: '37vw' }} className="fs30 lh45"><Trans i18nKey="image_slider.subtitle1" /></div>
        </div>
    </Slide1>,
    <Slide2>
        <div>
            <div className="fontThicker fs80 fw800"><Trans i18nKey="image_slider.title2" /></div>
            <div style={{ width: '37vw' }} className="fs30 lh45"><Trans i18nKey="image_slider.subtitle2" /></div>
        </div>
    </Slide2>,
    <Slide3>
        <div style={{ position: 'absolute', bottom: '5vh' }}>
            <div className="fontThicker fs80 fw800"><Trans i18nKey="image_slider.title3" /></div>
            <div style={{ width: '50vw' }} className="fs30 lh45"><Trans i18nKey="image_slider.subtitle3" /></div>
        </div>
    </Slide3>,
];

const ImageSlider = () => {
    const [count, setCount] = useState(0);

    useEffect(() => {
        const timerId = setInterval(() => {
            setCount((count) => count + 1);
        }, 10000);

        return () => clearInterval(timerId);
    }, []);

    const image = imageArray[count % imageArray.length];

    return (
        <div style={{ width: '100%' }}>{image}</div>
    );
};
export default ImageSlider;


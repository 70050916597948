
const AWS = require('aws-sdk')
const fs = require('fs')

// kiosk-ui account
export const uploadToS3 = (photo, name) => {
    var AWS_ACCESS_KEY_ID = process.env.AWS_ACCESS_KEY_ID ? process.env.AWS_ACCESS_KEY_ID : 'AKIASINBVZO3MBJ557N3';
    var AWS_SECRET_ACCESS_KEY = process.env.AWS_SECRET_ACCESS_KEY ? process.env.AWS_SECRET_ACCESS_KEY : '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';
    var AWS_BUCKET_NAME = process.env.AWS_BUCKET_NAME ? process.env.AWS_BUCKET_NAME : 'grading-photos';
    var AWS_REGION = process.env.AWS_REGION ? process.env.AWS_REGION : 'eu-central-1';

    const s3 = new AWS.S3({
        accessKeyId: AWS_ACCESS_KEY_ID,
        secretAccessKey: AWS_SECRET_ACCESS_KEY,
        region: AWS_REGION
    })


    const filename = name + '.jpg' // the file to upload
    const fileContent = photo //fs.readFileSync(filepath + filename)
    let now = new Date()

    const curdate = now.toISOString().substring(0, 10); // this must be calculated according to current date

    const params = {
        Bucket: AWS_BUCKET_NAME,
        Key: `${curdate}/${filename}`,
        Body: fileContent
    }

    s3.upload(params, (err, data) => {
        if (err) {
            console.log(err)
        }
    })
}
import { withTranslation, Trans } from 'react-i18next';
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from '../../utils/context';
import { MainLayout, Top, Center, Bottom } from '../../layouts/MainLayout/MainLayout';
import { ActionButton, MainVideo, RateExperience, } from '../../components';

const Print = (props) => {
    const { t, i18n } = props;
    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    const [show, setShow] = useState(false);

    useEffect(() => {
        dispatch({ type: "userJourney", payload: 'print' })
    }, [])

    return (
        <>
            <MainLayout noLogo>
                <Top></Top>
                <Center>

                </Center>
                <Bottom>
                    <div className='d-flex flex-column align-items-center justify-content-center'>
                        <MainVideo video='Print/print.mp4' />
                        <div className='fs48 lh60 fw600 text-center'><Trans i18nKey="print" /></div>
                        <ActionButton onClick={() => window.location.href = process.env.PUBLIC_URL + '/'} style={{ width: '26vw', marginTop: '' }} className='my-5 pb-3'><Trans i18nKey="done" /></ActionButton>
                    </div>
                </Bottom>
            </MainLayout>
            <RateExperience show={show} onHide={() => setShow(false)} />
        </>
    );
}

export default withTranslation()(Print);
import React from "react";

const MainImage = (props) => {
    return (
        <div style={props.style} className={props.className} onClick={props.onClick}>
            <img src={`images/${process.env.REACT_APP_THEME}/${props.img}`} alt={props.img} style={{ maxHeight: props.height, height: '100%' }} />
        </div>
    );
}

export default MainImage;
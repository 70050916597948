import { withTranslation, Trans } from 'react-i18next';
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from '../../utils/context';
import { MainLayout, Center, BottomLeft, Top } from '../../layouts/MainLayout/MainLayout';
import { ExitPopup, ImageSlider, Popup, ActionButton, MainImage } from '../../components';
import { navigate } from '@reach/router';
import axios from 'axios';
import io from "socket.io-client";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { flow } from '../../constants';
import * as sdk from '../../actions/sdk';

const InspectionComplete = (props) => {
    const { t, i18n } = props;
    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    const [imei, setImei] = useState('');
    const [imsi, setImsi] = useState('');
    const [showCancel, setShowCancel] = useState(false);
    const [show, setShow] = useState(false);
    const [image, setImage] = useState('');
    const [title, setTitle] = useState('');
    const [subtitle, setSubtitle] = useState('');

    var toastText;
    const notify = () => toast(toastText);
    useEffect(() => {
        const timeout = setTimeout(() => {
            navigate(process.env.PUBLIC_URL + '/identification-failed', { replace: true })
        }, 120000);
        return () => {
            clearTimeout(timeout);
        }
    }, [])
    useEffect(() => {
        dispatch({ type: "userJourney", payload: 'inspection-complete' })
        let isApiSubscribed = true;

        sdk.antennaStart()
            .then(() => {
                if (isApiSubscribed) {
                    const socket = io(`${process.env.REACT_APP_SOCKET_URL}`, { path: '/sdk/socket.io' });
                    socket.on('connection', (conn) => {
                        console.log("connected")
                    })
                    socket.on('error', (error) => {
                        console.log('error')
                        console.log(error)
                    })
                    socket.on('connect_error', (error) => {
                        console.log('connect_error')
                        console.log(error)
                    })
                    console.log(socket)
                    socket.on("imei", (data) => {
                        console.log('GET IMEI:');
                        console.log(data.imei);
                        setImei(data.imei)
                        setImsi(data.imsi)
                        dispatch({ type: "deviceInfo", payload: { imei: data.imei, imsi: data.imsi } })
                        if (data.imei !== state.deviceInfo.imei)
                            navigate(process.env.PUBLIC_URL + '/identification-failed', { replace: true })
                        socket.destroy();
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                toastText = <div className='fs24 text-center'>{error}</div>
                if (process.env.REACT_APP_DEBUG === '1')
                    notify();
            });

        return () => {
            isApiSubscribed = false;
        };
    }, [])


    useEffect(() => {

        let isApiSubscribed = true;
        if (imei !== '' && imsi !== '') {
            axios.post(process.env.REACT_APP_IMEI_SERVICE_URL, {
                "imei": imei
            }).then((res) => {
                if (isApiSubscribed) {
                    dispatch({ type: "deviceInfo", payload: res.data })
                    console.log(res.data);
                    console.log('CHECK IMEI')
                    const timeout = setTimeout(() => {
                        var imsiCheck = imsi.slice(0, 5);
                        if ((res.data.fmiOn || res.data.fmiON) && imsiCheck !== '11199') {
                            setShow(true);
                            setImage('Offer/fmi_sim.png')
                            setTitle(<Trans i18nKey="check_popup.title1" />);
                            setSubtitle(<div>1. <Trans i18nKey="check_popup.fmi" /><br />2. <Trans i18nKey="check_popup.sim" /></div>);
                            dispatch({ type: 'fmi_sim', payload: { fmi_sim: true } })
                        }
                        else if ((res.data.fmiOn || res.data.fmiON) && imsiCheck === '11199') {
                            setShow(true);
                            setImage('Offer/fmi.png')
                            setTitle(<Trans i18nKey="check_popup.title2" />);
                            setSubtitle(<Trans i18nKey="check_popup.fmi" />);
                            dispatch({ type: 'fmi_sim', payload: { fmi: true } })
                        }
                        else if (!res.data.fmiOn && !res.data.fmiON && imsiCheck !== '11199') {
                            setShow(true);
                            setImage('Offer/sim.png')
                            setTitle(<Trans i18nKey="check_popup.title2" />);
                            setSubtitle(<Trans i18nKey="check_popup.sim" />);
                            dispatch({ type: 'fmi_sim', payload: { sim: true } })
                        }
                        else {
                            setTimeout(() => {
                                navigate(process.env.PUBLIC_URL + flow.offer.next_button.payment, { replace: true })
                            }, 100);
                            console.log('payment')
                        }
                    }, 100);
                    return () => {
                        clearTimeout(timeout);
                    }
                }
            })
                .catch(function (error) {
                    console.log(error);
                    navigate(process.env.PUBLIC_URL + '/identification-failed', { replace: true })
                });
        }
        return () => {
            isApiSubscribed = false;
        };
    }, [imei, imsi])


    return (
        <>
            <MainLayout noLogo>
                <Top>
                    <ImageSlider />
                </Top>
                <Center>
                    <div className='my-5 fs30 fw600'><Trans i18nKey="inspection.identifying" /></div>
                    <div className="loader-line-identifying mx-4"></div>
                    <div className='d-flex justify-content-center mt-5'>
                        <div onClick={() => setShowCancel(true)} className='fs30 px-5 py-4' style={{ border: '2px solid #F2F2F2', borderRadius: '6.5rem' }}><Trans i18nKey="inspection.cancel" /></div>
                    </div>
                </Center>
                <BottomLeft>
                    <ToastContainer position='bottom-left' />
                </BottomLeft>
            </MainLayout>
            <ExitPopup show={showCancel} onHide={() => setShowCancel(false)} />
            <Popup show={show} onHide={() => setShow(false)}>
                <div className='center flex-column'>
                    <div className='center'>
                        <MainImage style={{ marginRight: '8vw' }} img={image} />
                        <div className='d-flex flex-column my-5 pb-5'>
                            <div className='fs38 lh48 fw600 mb-5'>{title}</div>
                            <div className='fs30 lh40  mb-5 '>{subtitle}</div>
                        </div>
                    </div>
                    <div className='d-flex'>
                        <ActionButton className='mx-4' to={process.env.PUBLIC_URL + flow.offer.next_button.fmi_sim} width='27vw'><Trans i18nKey="next_button" /></ActionButton>
                    </div>
                </div>
            </Popup>
        </>
    );
}

export default withTranslation()(InspectionComplete);

import 'bootstrap/dist/css/bootstrap.min.css';
import { Router } from "@reach/router";
import { withTranslation } from 'react-i18next';
import { AppContext } from './utils/context';
import React, { useEffect, useContext, useState } from 'react';
import Theme from "./utils/theme";
import './App.css';
import Language from './utils/language';
import Home from './pages/Home/home';
import Tutorial from './pages/Tutorial/Tutorial';
import Start from './pages/Start/Start';
import GetSim from './pages/GetSim/GetSim';
import SimQR from './pages/SimQR/SimQR';
import SimInstructions from './pages/SimInstructions/SimInstructions';
import Insert from './pages/Insert/Insert';
import Inspection from './pages/Inspection/Inspection';
import Identification from './pages/Identification/Identification';
import RemoteGrading from './pages/RemoteGrading/RemoteGrading';
import IdentificationFailed from './pages/IdentificationFailed/IdentificationFailed';
import Offer from './pages/Offer/Offer';
import DetailedReport from './pages/DetailedReport/DetailedReport';
import PaymentOptions from './pages/PaymentOptions/PaymentOptions';
import UserInformation from './pages/UserInformation/UserInformation';
import ConfirmPayment from './pages/ConfirmPayment/ConfirmPayment';
import WebappQR from './pages/WebappQR/WebappQR';
import WifiQR from './pages/WifiQR/WifiQR';
import ThankYouPage from './pages/ThankYouPage/ThankYouPage';
import OpenDrawer from './pages/OpenDrawer/OpenDrawer';
import CloseDrawer from './pages/CloseDrawer/CloseDrawer';
import history from "./history";
import InsertSim from './pages/InsertSim/InsertSim';
import IdentificationSuccess from './pages/IdentificationSuccess/IdentificationSuccess';
import DeviceOpenDrawer from './pages/CheckFmiSim/DeviceOpenDrawer';
import FmiInfo from './pages/CheckFmiSim/FmiInfo';
import SimInfo from './pages/CheckFmiSim/SimInfo';
import InsertComplete from './pages/CheckFmiSim/InsertComplete';
import CloseDrawerComplete from './pages/CheckFmiSim/CloseDrawerComplete'
import InspectionComplete from './pages/CheckFmiSim/InspectionComplete';
import Iban from './pages/Iban/Iban';
import TurnOnDevice from './pages/TurnOnDevice/TurnOnDevice';
import Print from './pages/Print/Print';
import ExitPage from './pages/ExitPage/ExitPage';
import RemoveDevice from './pages/RemoveDevice/RemoveDevice';
import Header from './header'
import AlignPhone from './pages/IdentificationFailed/AlignPhone';
import GoodBye from './pages/IdentificationFailed/GoodBye';
import RemoveCover from './pages/IdentificationFailed/RemoveCover';
import TurnOnScreen from './pages/IdentificationFailed/TurnOnScreen';
import PriceNotFound from './pages/IdentificationFailed/PriceNotFound';
import Accessories from './pages/Accessories/Accessories';
import Screen from './pages/Screen/Screen';
import NetworkErrorPopup from './components/NetworkErrorPopup/NetworkErrorPopup';
import * as health from './actions/health';
import * as sdk from './actions/sdk';



const App = () => {
    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    const [show, setShow] = useState(false);
    const MINUTE_MS = 10000;

    useEffect(() => {
        const interval = setInterval(() => {
            setShow(health.wifi());
        }, MINUTE_MS);

        return () => clearInterval(interval);
    }, [])


    return (
        <Theme>
            <NetworkErrorPopup show={show} onHide={() => setShow(false)} />
            <Router primary={false} history={history} basepath={process.env.PUBLIC_URL} >
                <Language path="/el" lang="el" />
                <Language path="/en" lang="en" />
                <Home path="/home" default />
                <Start path="/start" />
                <Header style={{ background: 'white', height: '100%' }} path={"/timer"}>
                    <Accessories path="accessories" />
                    <Screen path="screen" />
                    <Tutorial path="/tutorial" />
                    <TurnOnDevice path="/turn-on-device" />
                    <SimInstructions path="/sim-instructions" />
                    <GetSim path="/get-sim" />
                    <InsertSim path="/insert-sim" />
                    <Insert path="/insert" />
                    <FmiInfo path="/fmi-info" />
                    <SimInfo path="/sim-info" />
                    <InsertComplete path="/insert-complete" />
                    <RemoveDevice path="/remove-device" />
                    <ThankYouPage path="/thank-you-page" />
                </Header>
                <IdentificationFailed path="/identification-failed" />
                <PriceNotFound path="/price-not-found" />
                <AlignPhone path="/align-phone" />
                <GoodBye path="/good-bye" />
                <RemoveCover path="/remove-cover" />
                <TurnOnScreen path="/turn-on-screen" />
                <SimQR path="/sim-qr" />
                <WifiQR path="/wifi-qr" />
                <WebappQR path="/webapp-qr" />
                <OpenDrawer path="/open-drawer" />
                <CloseDrawer path="/close-drawer" />
                <DeviceOpenDrawer path="/device-open-drawer" />
                <CloseDrawerComplete path="/close-drawer-complete" />
                <InspectionComplete path="/inspection-complete" />
                <Inspection path="/inspection" />
                <Identification path="/identification" />
                <IdentificationSuccess path="/identification-success" />
                <RemoteGrading path="/remote-grading" />
                <Offer path="/offer" />
                <DetailedReport path="/detailed-report" />
                <PaymentOptions path="/payment-options" />
                <Iban path="iban" />
                <UserInformation path="/user-information" />
                <ConfirmPayment path="/confirm-payment" />
                <ExitPage path="/exit-page" />
                <Print path="/print" />
            </Router>
        </Theme>

    );
}

export default withTranslation()(App);
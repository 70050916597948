import { withTranslation, Trans } from 'react-i18next';
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from '../../utils/context';
import { MainLayout, Top, Center, Bottom } from '../../layouts/MainLayout/MainLayout';
import { MainCard, MainVideo, MainTitle } from '../../components';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as sdk from '../../actions/sdk';
import { UploadData } from '../../actions/uploadData';

const RemoveDevice = (props) => {
    const { t, i18n } = props;
    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    const [loading, setLoading] = useState(false);
    const notify = () => toast(<div className='fs24 text-center'>motion close</div>);

    useEffect(() => {
        dispatch({ type: "userJourney", payload: 'remove-device' })
    }, []);

    const handleClick = () => {
        UploadData(state);
        setTimeout(() => {
            setLoading(true)
            sdk.motionClose()
                .then(() => {
                    window.location.href = process.env.PUBLIC_URL + '/';
                })
                .catch(function (error) {
                    console.log(error);
                    if (process.env.REACT_APP_DEBUG === '1')
                        notify();
                });
        }, 100);

    }

    return (
        <>
            <MainLayout
                nextButton={{
                    text: <Trans i18nKey="done" />,
                    to: '#',
                    onClick: () => handleClick(),
                    disabled: loading
                }}
            >
                <Top></Top>
                <Center>
                    <MainCard className='align-items-center'>
                        <MainVideo style={{ margin: '0 6vw' }} video='RemoveDevice/remove_device.mp4' />
                        <MainTitle style={{ width: '45vw' }}><Trans i18nKey="remove_device" /></MainTitle>
                    </MainCard>
                    <ToastContainer position='bottom-left' />
                </Center>
                <Bottom></Bottom>
            </MainLayout>
        </>
    );
}

export default withTranslation()(RemoveDevice);
import { withTranslation } from 'react-i18next';
import React, { useContext, useEffect } from "react";
import { AppContext } from '../../utils/context';
import { Drawer } from '../../components';
import { navigate } from '@reach/router';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as sdk from '../../actions/sdk';

const CloseDrawer = (props) => {
    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    const notify = () => toast(<div className='fs24 text-center'>motion close</div>);

    useEffect(() => {
        let isApiSubscribed = true;
        dispatch({ type: "userJourney", payload: 'close-drawer' })
        sdk.motionClose()
            .then((response) => {
                if (isApiSubscribed) {
                    console.log(response);
                    navigate(process.env.PUBLIC_URL + '/inspection', { replace: true })
                }
            }).catch((error) => {
                console.log(error);
                if (process.env.REACT_APP_DEBUG === '1')
                    notify();
            });
        return () => {
            isApiSubscribed = false;
        }
    }, [])

    return (
        <>
            <Drawer close />
        </>
    );
}

export default withTranslation()(CloseDrawer);
import { Trans } from 'react-i18next';
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from '../../utils/context';
import { MainLayout, Top, Center, Bottom } from '../../layouts/MainLayout/MainLayout';
import { MainVideo, MainCard, Notification, MainTitle } from '../../components';
import styled from "styled-components";

const BorderBottom = styled.span`
    border-bottom: 8px solid ${({ theme: { colors } }) => colors.light_third};
    font-weight: 600;
`;

const Insert = (props) => {
    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        dispatch({ type: "userJourney", payload: 'insert' })
        dispatch({ type: "progress", payload: 'step5' })
        setLoading(true);
        const timeout = setTimeout(() => {
            setLoading(false);
        }, 4000);
        return () => {
            clearTimeout(timeout);
        }
    }, [])

    return (
        <>
            <MainLayout
                progress_bar
                exit
                nextButton={{
                    text: <Trans i18nKey="next_button" />,
                    to: process.env.PUBLIC_URL + '/close-drawer',
                }}
            >
                <Top></Top>
                <Center>
                    <MainCard className='center pt-5'>
                        <MainVideo style={{ marginRight: '5vw' }} video='Insert/insert.mp4' />
                        <MainTitle style={{ width: '45vw' }}>
                            <Trans i18nKey="insert.title" />
                        </MainTitle>
                        <Notification loading={loading}><div><Trans i18nKey="insert.notification" /></div></Notification>
                    </MainCard>
                </Center>
                <Bottom></Bottom>
            </MainLayout>
        </>
    );
}

export default Insert;
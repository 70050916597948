import { withTranslation, Trans } from 'react-i18next';
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from '../../utils/context';
import { MainLayout, Top, Center, Bottom } from '../../layouts/MainLayout/MainLayout';
import { MainVideo, MainCard, InfoButton, Notification, QRPopup, BorderQRCode } from '../../components';
import QRCode from "react-qr-code";
import styled from "styled-components";
import { navigate } from '@reach/router';
import axios from 'axios';

const BorderBottom = styled.span`
  border-bottom: 8px solid ${({ theme: { colors } }) => colors.light_third};
  font-weight: 600;
`;

const WebappQR = (props) => {
    const { t, i18n } = props;
    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [webappQRCode, setWebappQRCode] = useState(false);

    useEffect(() => {
        setLoading(true);
        dispatch({ type: "userJourney", payload: 'webapp-qr' })
        // TODO: handle clean up
        setTimeout(() => {
            setWebappQRCode('https://kiosk-webapp.greenpanda.io/' + (state.sessionInfo.uuid ?? ""))
            console.log('https://kiosk-webapp.greenpanda.io/' + (state.sessionInfo.uuid ?? ""))
            setLoading(false);
        }, 4000);
    }, []);
    const cameraDoesntWork = () => {
        navigate(process.env.PUBLIC_URL + '/open-drawer', { replace: true })
        dispatch({ type: "sessionInfo", payload: { camera: false } })
    }

    return (
        <>
            <MainLayout
                exit
                nextButton={{
                    to: process.env.PUBLIC_URL + '/open-drawer',
                    text: <Trans i18nKey="webapp_qr.next_button" />,
                    disabled: loading
                }}
                backButton={{ to: process.env.PUBLIC_URL + '/wifi-qr' }}
            >
                <Top></Top>
                <Center>
                    <MainCard className='align-items-center'>
                        {webappQRCode && <div className='d-flex justify-content-center align-items-center' style={{ width: '400px', height: '400px' }}>
                            <BorderQRCode><QRCode size={150} value={webappQRCode} /></BorderQRCode>
                        </div>}
                        {!webappQRCode && <MainVideo video='WebappQR/webapp_qr.mp4' />}
                        <div>
                            <div className='fs38 lh48 fw400 mb-5 pb-5'><Trans i18nKey="webapp_qr.title1" /><BorderBottom>Pandas app</BorderBottom><Trans i18nKey="webapp_qr.title2" /></div>
                            <InfoButton className='me-5' onClick={() => setShow(true)}><Trans i18nKey="webapp_qr.info_button" /></InfoButton>
                        </div>
                        <Notification loading={loading}><u onClick={() => cameraDoesntWork()}><Trans i18nKey="webapp_qr.notification" /></u></Notification>
                    </MainCard>
                </Center>
                <Bottom></Bottom>
            </MainLayout>
            <QRPopup show={show} onHide={() => setShow(false)} />
        </>
    );
}

export default withTranslation()(WebappQR);
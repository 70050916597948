import React, { useState, useContext, useEffect } from "react";
import { AppContext } from '../../utils/context';
import styled, { keyframes } from "styled-components"
import packageJson from '../../../package.json';
import { ProgressBar, Step } from "react-step-progress-bar";
import "react-step-progress-bar/styles.css";
import { ExitPopup, BackButton, ExitButton, NextButton } from '../../components';
import { flow } from '../../constants';

const fadeInAnimation = keyframes`
    0% {
        opacity: 0.4;
    }
    100% {
        opacity: 1;
    }
`;
const Layout = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: ${props => !props.height ? '100vh' : props.height};
    padding: 5vh 5vw 2vh;
    background: #FFFFFF;
    background-image: ${props => props.bg};
    background-position: center;
    background-size: cover;
    animation: ${fadeInAnimation} ease 1s;
    animation-iteration-count: 1;
      animation-fill-mode: forwards;
`;

const MainLayout = (props) => {
    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    const { noLogo, exit, backButton, nextButton } = props;
    const [show, setShow] = useState(false);

    const children = Array.isArray(props.children) ? props.children : []
    const top_left = children.find(({ type }) => type === TopLeft)
    const top_right = children.find(({ type }) => type === TopRight)
    const top = children.find(({ type }) => type === Top)
    const center = children.find(({ type }) => type === Center)
    const bottom = children.find(({ type }) => type === Bottom)
    const bottom_left = children.find(({ type }) => type === BottomLeft)
    const bottom_right = children.find(({ type }) => type === BottomRight)

    var checks = state.progress[state.progress.length - 1];
    var percent = flow.steps.steps_obj[checks] / flow.steps.steps_length * 100;

    useEffect(() => () => {
        dispatch({ type: "percent", data: { percent: percent } })
    }, [percent])


    return (
        <Layout onContextMenu={(e) => e.preventDefault()} bg={props.bg} height={props.height}>
            <div>
                <div className="d-flex justify-content-between">
                    {top}
                    {props.progress_bar ? <> <div className="mt-5" style={{ width: '85%' }}>
                        <ProgressBar height={5} percent={percent} filledBackground={flow.progress_bar}>
                            {Object.keys(flow.steps.steps_obj).map((key, index) => {
                                return (
                                    <Step key={index}
                                        children={({ accomplished }) => (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    width: 5,
                                                    height: 10,
                                                    backgroundColor: "#FFFFFF",
                                                    accomplished: 'true'
                                                }}
                                            ></div>
                                        )}
                                    />);
                            })}
                        </ProgressBar>
                    </div>
                        <div style={{ float: 'right' }}>{exit ? <ExitButton to='#' onClick={() => setShow(true)} /> : <div>{top_right}</div>}</div></>
                        : <><div>{top_left}</div><div>{exit ? <ExitButton to='#' onClick={() => setShow(true)} /> : <div>{top_right}</div>}</div></>}
                </div>
            </div>
            <div>
                {center}
            </div>
            <div>
                <div className='center'>{bottom}</div>
                <div className='d-flex justify-content-between'>
                    {backButton ? <BackButton to={props.backButton.to} onClick={props.backButton.onClick} /> : <div>{bottom_left}</div>}
                    {nextButton ? <NextButton to={!props.disabled ? props.nextButton.to : '#'} disabled={props.nextButton.disabled} onClick={props.nextButton.onClick} >{props.nextButton.text}</NextButton> : <div>{bottom_right}</div>}
                </div>
                <div className='mt-5' style={{ opacity: 0.3, fontSize: '1.4rem' }}>{packageJson.version}</div>
            </div>
            <ExitPopup show={show} onHide={() => setShow(false)} />
        </Layout>
    );
}

const TopLeft = ({ children }) => <>{children}</>
const TopRight = ({ children }) => <>{children}</>
const Top = ({ children }) => <>{children}</>
const Center = ({ children }) => <>{children}</>
const BottomLeft = ({ children }) => <>{children}</>
const BottomRight = ({ children }) => <>{children}</>
const Bottom = ({ children }) => <>{children}</>

export { MainLayout, TopLeft, TopRight, Top, Center, BottomLeft, BottomRight, Bottom };







import { withTranslation, Trans } from 'react-i18next';
import React, { useContext, useEffect } from "react";
import { AppContext } from '../../utils/context';
import { MainLayout, Top, Center, Bottom } from '../../layouts/MainLayout/MainLayout';
import { MainVideo, MainCard, MainTitle } from '../../components';
import { navigate } from '@reach/router';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as sdk from '../../actions/sdk';


const FmiSimOpenDrawer = (props) => {
    const { t, i18n } = props;
    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    const notify = () => toast(<div className='fs24 text-center'>motion open</div>);

    useEffect(() => {
        dispatch({ type: "userJourney", payload: 'device-open-drawer' })
        let isApiSubscribed = true;
        sdk.antennaStop().then((response) => {
            console.log(response);
        }).then(() =>
            sdk.motionOpen()).then((response) => {
                if (isApiSubscribed) {
                    console.log(response);
                    if (state.check.fmi_sim || state.check.fmi)
                        navigate(process.env.PUBLIC_URL + '/timer/fmi-info', { replace: true })
                    else
                        navigate(process.env.PUBLIC_URL + '/timer/sim-info', { replace: true })
                }
            }).catch((e) => {
                if (process.env.REACT_APP_DEBUG === '1')
                    notify();
                // TODO: catch hardware errors
                console.log(e);
            });
        return () => {
            isApiSubscribed = false;
        }
    }, [])

    return (
        <>
            <MainLayout exit>
                <Top></Top>
                <Center>
                    <MainCard className="align-items-center justify-content-center">
                        <MainVideo style={{ marginRight: '' }} video='DeviceOpenDrawer/device_open_drawer.mp4' />
                        <div>
                            <MainTitle style={{ width: '45vw' }}><Trans i18nKey="open_drawer" /></MainTitle>
                        </div>
                    </MainCard>
                </Center>
                <Bottom></Bottom>
            </MainLayout>
        </>
    );
}

export default withTranslation()(FmiSimOpenDrawer);
import React, { useContext } from "react";
import { AppContext } from '../../utils/context';
import { MainButton, MainImage } from '..';

const PaymentButton = (props) => {
    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    return (
        <MainButton onClick={props.onClick} className='d-flex justify-content-between my-4 px-5' width='100%' height='160px'>
            <div className='d-flex align-items-center'>
                <MainImage className='me-5 pe-5' img={`PaymentOptions/${props.img}.png`} height='10vh' />
                <div>
                    <div className='fs30 fw400'>{props.title}</div>
                </div>
            </div>
            <div className='fs40 fw600'>{props.finalPrice}€</div>
        </MainButton>
    );
}

export default PaymentButton;
import { navigate } from '@reach/router';
import axios from 'axios';
import { flow } from '../constants';

export const get = (path) => {
    const cancelTokenSource = axios.CancelToken.source()
    const promise = () => {
        return axios.get(`${process.env.REACT_APP_URL}${path}`, {
            cancelToken: cancelTokenSource.token,
            'Content-Type': 'Access-Control-Allow-Origin'
        })
    }
    //  cancelTokenSource.cancel();
    return {
        exec: promise, cancel: cancelTokenSource.cancel
    }
}

export const post = (path) => {
    const cancelTokenSource = axios.CancelToken.source()
    const promise = () => {
        return axios.post(`${process.env.REACT_APP_URL}${path}`, {
            'Content-Type': 'Access-Control-Allow-Origin'
        }, {
            cancelToken: cancelTokenSource.token
        })
    }
    return {
        exec: promise, cancel: cancelTokenSource.cancel
    }
}
//Location
export const Location = () => {
    return get('/v1/location').exec();
}

//Sim dispenser
export const simDispense = () => {
    return get('/v1/sim-dispenser/dispense').exec();
}

//Cameras
export const cameraCaptureAll = () => {
    return get('/v1/camera/capture/all').exec();
}

//Antenna
export const antennaStart = () => {
    return post('/v1/antenna/control/start').exec();
}
export const antennaStop = () => {
    return post('/v1/antenna/control/stop').exec();
}

//Motion
export const motionMotion = () => {
    return post('/v1/motion/move/motion').exec();
}
export const motionIdle = () => {
    return post('/v1/motion/move/idle').exec();
}
export const motionCamera = () => {
    return post('/v1/motion/move/camera').exec();
}
export const motionFaraday = () => {
    return post('/v1/motion/move/faraday').exec();
}
export const motionOpen = () => {
    return post('/v1/motion/move/open').exec();
}
export const motionClose = () => {
    return post('/v1/motion/move/close').exec();
}
export const motionStore = () => {
    return post('/v1/motion/move/store').exec();
}
//Print
let now = new Date()
const curdate = now.toJSON().slice(0, 10).split('-').reverse().join('.');
export const printerPrint = (state) => {
    axios.post(`${process.env.REACT_APP_URL}/v1/printer/voucher/print`, {
        "voucherCode": state.userInformation.payment_value,
        "localPrice": state.priceInfo.localPrice,
        "currencySymbol": flow.currencySymbol, //euro symbol
        "date": curdate,    // This is the date in text format  .. must be formatted by Kiosk UI
        "clientEmail": state.userInformation.email,
        "language": state.sessionInfo.language,
        "partner": {
            "name": flow.partner.name, // Partner name
            "code": flow.partner.code,   // flow code
            "expiration": "6"
        }
    }).then((res) => {
        navigate(process.env.PUBLIC_URL + '/print', { replace: true })
        console.log(res)
    }).catch((res) => {
        console.log(res)
    })
}
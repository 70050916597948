import { withTranslation, Trans } from 'react-i18next';
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from '../../utils/context';
import { MainLayout, Top, Center, Bottom } from '../../layouts/MainLayout/MainLayout';
import { MainImage, MainCard, MainTitle, MainButton } from '../../components';
import { flow } from '../../constants';

const SimInstructions = (props) => {
    const { t, i18n } = props;
    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    const [hasSim, setHasSim] = useState(false);
    const [hasntSim, setHasntSim] = useState(false);

    useEffect(() => {
        dispatch({ type: "userJourney", payload: 'sim-instructions' })
        dispatch({ type: "progress", payload: 'step1' })
    }, [])

    return (
        <>
            <MainLayout
                exit
                progress_bar
                nextButton={{
                    text: <Trans i18nKey="next_button" />,
                    to: hasSim ? process.env.PUBLIC_URL + flow.sim_instructions.next_button.yes : process.env.PUBLIC_URL + flow.sim_instructions.next_button.no,
                    disabled: !hasSim && !hasntSim
                }}
                backButton={{ to: process.env.PUBLIC_URL + flow.sim_instructions.back_button }}
            >
                <Top></Top>
                <Center>
                    <MainCard large className='justify-content-center align-items-center'>
                        <div className='d-flex flex-column align-items-center'>
                            <MainTitle className="pb-5"><Trans i18nKey="sim_instructions.title" /></MainTitle>
                            <div className='d-flex'>
                                <MainButton border={hasntSim ? ({ theme: { colors } }) => colors.primary : '#E6E6E6'} onClick={() => { setHasntSim(!hasntSim); setHasSim(false) }} className='mx-3 flex-column' width='30vw' height='46vh'>
                                    <MainImage className='me-5' img='SimInstructions/no_icon.png' height='21vh' />
                                    <MainTitle className='mt-4 mb-3'><Trans i18nKey="sim_instructions.no" /></MainTitle>
                                    <div className='fs20 lh24 fw400'><Trans i18nKey="sim_instructions.has_no_sim" /></div>
                                </MainButton>
                                <MainButton border={hasSim ? ({ theme: { colors } }) => colors.primary : '#E6E6E6'} onClick={() => { setHasSim(!hasSim); setHasntSim(false) }} className='mx-3 flex-column' width='30vw' height='46vh'>
                                    <MainImage className='mt-4 me-5' img='SimInstructions/yes_icon.png' height='21vh' />
                                    <MainTitle className='mt-4 mb-3'><Trans i18nKey="sim_instructions.yes" /></MainTitle>
                                    <div className='fs20 lh24 fw400 text-center'><Trans i18nKey="sim_instructions.has_sim" /></div>
                                </MainButton>
                            </div>
                        </div>
                    </MainCard>
                </Center>
                <Bottom></Bottom>
            </MainLayout>
        </>
    );
}

export default withTranslation()(SimInstructions);
import { withTranslation, Trans } from 'react-i18next';
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from '../../utils/context';
import { MainLayout, Top, Center, Bottom } from '../../layouts/MainLayout/MainLayout';
import { ExitPopup, MainVideo } from '../../components';
import { navigate } from '@reach/router';
import io from 'socket.io-client';
import styled from "styled-components";
import { flow } from '../../constants';

const Slide1 = styled.div`
    background-image:url(images/${process.env.REACT_APP_THEME}/RemoteGrading/bg.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 68vh;
    -webkit-animation: myfirst 5s ; /* Chrome, Safari, Opera */
    animation: myfirst 5s ;
`;


const Identification = (props) => {
    const { t, i18n } = props;
    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    const deviceInfo = state.deviceInfo ? state.deviceInfo : '';
    const brand = state.deviceInfo ? state.deviceInfo.brand : '';
    const model = state.deviceInfo ? state.deviceInfo.model : '';
    const [show, setShow] = useState(false);
    var calcPrice = flow.localPrices;


    useEffect(() => {
        dispatch({ type: "userJourney", payload: 'remote-grading' })
        const socketRemote = io(process.env.REACT_APP_REMOTE_GRADER_URL, { withCredentials: false })
        socketRemote.on('connect', () => {
            console.log("CONNECTED!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")
            var body = {
                "id": "photos." + state.sessionInfo.uuid,
                "store": state.sessionInfo.location ? state.sessionInfo.location.name : '',
                "phone": brand + ' ' + model,
                "isWorking": true,
                "ttl": "60000",
                "isEmptyDrawer": false,
                "misMatchPercentage": null,
                "useApplication": false,
                "isRegrade": false,
                "photos": state.deviceInfo.photos
            }
            socketRemote.emit('/photos', body, (err, response, data) => {
                console.log(data)
                dispatch({ type: "gradingInfo", payload: data })
                console.log(response)
                // TRANSLATE DATA TO CONDITION
                // DISPATCH CONDITION
                if (data.phonePositionAnswer === 'Χμμ.. φαίνεται ότι η οθόνη σας είναι σβηστή. Τοποθετήστε τη συσκευή με ανοιχτή την οθόνη στο συρτάρι.') {
                    navigate(process.env.PUBLIC_URL + "/turn-on-screen", { replace: true })
                } else if (data.phonePositionAnswer === "Παρακαλώ τοποθετείστε το κινητό μέσα στο πράσινο πλαίσιο με την οθόνη να κοιτάει προς τα πάνω." ||
                    data.phonePositionAnswer === "Παρακαλώ τοποθετείστε ένα κινητό την φορά." ||
                    data.isImageClear === false) {
                    navigate(process.env.PUBLIC_URL + "/align-phone", { replace: true })
                } else if (data.phonePositionAnswer === "Αυτή τη στιγμή δεν μπορούμε να αξιολογήσουμε συσκευές με σβηστή οθόνη. Θα χαρούμε να σας εξυπηρετήσουμε σύντομα ξανά!") {
                    navigate(process.env.PUBLIC_URL + "/good-bye", { replace: true })
                } else if (data.phonePositionAnswer === "Παρακαλώ αφαιρέστε τη θήκη και το προστατευτικό τζαμάκι οθόνης.") {
                    navigate(process.env.PUBLIC_URL + "/remove-cover", { replace: true })
                }
                else {
                    if (data.hasWaterDamage | data.hasScreenDamage | data.hasGeneralDamage | data.hasButtonDamage | !state.sessionInfo.camera |
                        (state.webappInfo.digitizer === '0' | state.webappInfo.sound === '0' | state.webappInfo.biometrics === '0' | state.webappInfo.front_camera === '0' | state.webappInfo.back_camera === '0')) {
                        dispatch({ type: "gradingInfo", payload: { condition: 'faulty' } })
                        dispatch({
                            type: "priceInfo",
                            payload: {
                                price: Math.round(deviceInfo.price * deviceInfo.faulty),
                                localPrice: Math.round(deviceInfo.localPrices[flow.localPrices] * deviceInfo.faulty)
                            }
                        })
                    }
                    else if (data.hasDentsOnSidesDamage) {
                        dispatch({ type: "gradingInfo", payload: { condition: 'poor' } })
                        dispatch({
                            type: "priceInfo",
                            payload: {
                                price: Math.round(deviceInfo.price * deviceInfo.poor),
                                localPrice: Math.round(deviceInfo.localPrices[flow.localPrices] * deviceInfo.poor)
                            }
                        })
                    }
                    else if (data.isExceptional) {
                        dispatch({ type: "gradingInfo", payload: { condition: 'very_good' } })
                        dispatch({
                            type: "priceInfo",
                            payload: {
                                price: Math.round(deviceInfo.price * deviceInfo.very_good),
                                localPrice: Math.round(deviceInfo.localPrices[flow.localPrices] * deviceInfo.very_good)
                            }
                        })
                    }
                    else {
                        dispatch({ type: "gradingInfo", payload: { condition: 'good' } })
                        dispatch({
                            type: "priceInfo",
                            payload: {
                                price: Math.round(deviceInfo.price * deviceInfo.good),
                                localPrice: Math.round(deviceInfo.localPrices[flow.localPrices] * deviceInfo.good),
                            }
                        })
                    }
                    socketRemote.close();
                    navigate(process.env.PUBLIC_URL + "/offer", { replace: true })
                    dispatch({ type: "sessionInfo", payload: { evaluation: 1 } })
                }
            })
        });
        socketRemote.on("connect_error", (err) => {
            navigate(process.env.PUBLIC_URL + "/identification-failed", { replace: true })
        });
        socketRemote.on('disconnect', function () {
            console.log('disconnected!!!!!!!!!!!!!!!!!!!!!!!!!!!!!');
        });
        return () => {
            socketRemote.destroy();
        }
    }, []);


    return (
        <>
            <MainLayout noLogo>
                <Top>
                    <div style={{ width: '100%' }}>
                        <Slide1></Slide1>
                    </div>
                </Top>
                <Center>
                    <div className='my-5 fs30 fw600'><Trans i18nKey="remote_grading.preparing_offer" /></div>
                    <div className='d-flex'>
                        <div className="completed-line"></div>
                        <div className="completed-line mx-4"></div>
                        <div className="loader-line-grading"></div>
                    </div>
                    <div className='d-flex justify-content-center mt-5'>
                        <div onClick={() => setShow(true)} className='fs30 px-5 py-4' style={{ border: '2px solid #F2F2F2', borderRadius: '6.5rem' }}><Trans i18nKey="inspection.cancel" /></div>
                    </div>
                </Center>
            </MainLayout>
            <ExitPopup show={show} onHide={() => setShow(false)} />
        </>
    );
}

export default withTranslation()(Identification);
import { withTranslation, Trans } from 'react-i18next';
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from '../../utils/context';
import { MainLayout, Top, Center, Bottom } from '../../layouts/MainLayout/MainLayout';
import { MainVideo, MainCard, InfoButton, QRPopup, BorderQRCode, Notification } from '../../components';
import QRCode from "react-qr-code";
import styled from "styled-components";
import axios from 'axios';
import { navigate } from '@reach/router';

const BorderBottom = styled.span`
  border-bottom: 8px solid ${({ theme: { colors } }) => colors.light_third};
  font-weight: 600;
`;

const WifiQR = (props) => {
    const flow = require(`../../flow/${process.env.REACT_APP_FLOW}`);
    const { t, i18n } = props;
    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(true);
    const [wifiQRCode, setWifiQRCode] = useState(false);
    const [ssid, setSsid] = useState(process.env.REACT_APP_WIFI === 'travel' ? 'TravelKioskWifi' : 'Pandas' + parseInt(Math.random() * 100));
    const [pass, setPass] = useState(process.env.REACT_APP_WIFI === 'travel' ? 'Panda123' : 'Password' + parseInt(Math.random() * 100));

    // const [ssid, setSsid] = useState('GPHQ');
    // const [pass, setPass] = useState('HQgreenpanda123');


    const header = { 'Content-Type': 'Access-Control-Allow-Origin' }
    useEffect(() => {
        dispatch({ type: "userJourney", payload: 'wifi-qr' })
        // TODO: handle clean up
        axios.post(`${process.env.REACT_APP_URL}/v1/wifi/control/start`, { 'ssid': ssid, 'pass': pass }, header)
            .then(function (response) {
                console.log(response);
                setWifiQRCode(`WIFI:S:${ssid};P:${pass};H:false;T:WPA;`)
                setLoading(false);
            })
            .catch(function (error) {
                const timeout = setTimeout(() => {
                    console.log("MOCK: wifi start");
                    console.log("ssid", ssid);
                    console.log("pass", pass);
                    setWifiQRCode(`WIFI:S:${ssid};P:${pass};H:false;T:WPA;`)
                    setLoading(false);
                }, 1000);
                return () => {
                    clearTimeout(timeout);
                }
            });
    }, [])
    const cameraDoesntWork = () => {
        navigate(process.env.PUBLIC_URL + '/open-drawer', { replace: true })
        dispatch({ type: "sessionInfo", payload: { camera: false } })
    }


    return (
        <>
            <MainLayout
                exit
                nextButton={{
                    text: <Trans i18nKey="wifi_qr.next_button" />,
                    to: process.env.PUBLIC_URL + '/webapp-qr',
                    disabled: loading
                }}
                backButton={{
                    to: process.env.PUBLIC_URL + flow.wifi_qr.back_button
                }}
            >
                <Top></Top>
                <Center>
                    <MainCard className='align-items-center justify-content-center'>
                        {wifiQRCode && <div className='d-flex justify-content-center align-items-center' style={{ width: '400px', height: '400px' }}>
                            <BorderQRCode><QRCode size={150} value={wifiQRCode} /></BorderQRCode>
                        </div>}
                        {!wifiQRCode && <MainVideo video='WifiQR/wifi_qr.mp4' />}
                        <div>
                            <div className='fs38 lh48 fw600 mb-5 pb-5'><Trans i18nKey="wifi_qr.title" /><BorderBottom>Pandas network</BorderBottom></div>
                            <InfoButton className='me-5' onClick={() => setShow(true)}><Trans i18nKey="wifi_qr.info_button" /></InfoButton>
                        </div>
                        <Notification loading={loading}><u onClick={() => cameraDoesntWork()}><Trans i18nKey="wifi_qr.notification" /></u></Notification>
                    </MainCard>
                </Center>
                <Bottom></Bottom>
            </MainLayout>
            <QRPopup show={show} onHide={() => setShow(false)} />
        </>
    );
}

export default withTranslation()(WifiQR);
import { Trans } from 'react-i18next';
import React, { useContext, useState, useEffect } from "react";
import { AppContext } from '../../utils/context';
import { navigate } from '@reach/router';
import { MainLayout, Top, Center, BottomLeft } from '../../layouts/MainLayout/MainLayout';
import { BackButton, MainCard, MainTitle, MainVideo } from '../../components';
import * as sdk from '../../actions/sdk';

const SimInfo = (props) => {
    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    const [loading, setLoading] = useState(false);
    let isApiSubscribed = true;

    useEffect(() => {
        dispatch({ type: "userJourney", payload: 'get-sim' })
        return () => {
            isApiSubscribed = false;
        };

    }, [])
    const onNextButtonClick = () => {
        setLoading(true);
        sdk.simDispense().then(() => {
            if (isApiSubscribed)
                navigate(process.env.PUBLIC_URL + '/timer/insert-sim', { replace: true })
        }).catch((e) => {
            // TODO: catch hardware errors
            console.log(e);
        });
    }


    return (
        <MainLayout
            exit
            progress_bar
            nextButton={{
                text: <Trans i18nKey="next_button" />,
                to: '#',
                onClick: onNextButtonClick,
                disabled: loading
            }}
        >
            <Top></Top>
            <Center>
                <MainCard className='center'>
                    <MainVideo style={{ marginRight: '5vw' }} video='GetSim/get_sim.mp4' />
                    <div style={{ width: '34vw' }}>
                        <MainTitle className="py-3"><Trans i18nKey="get_sim.title" /></MainTitle>
                        <div className='mt-5 fs26'><Trans i18nKey="get_sim.subtitle" /></div>
                    </div>
                </MainCard>
            </Center>
            <BottomLeft>{state.check.fmi_sim && <BackButton to={process.env.PUBLIC_URL + '/timer/fmi-info'} />}</BottomLeft>
        </MainLayout>
    );
}

export default SimInfo;
import axios from 'axios';
import { flow } from '../constants';
export const Webhook = (state) => {
    const header = { 'Content-Type': 'application/json' };
    let currentDate = new Date()
    const timestamp = currentDate.getTime();
    Number.prototype.padLeft = function (base, chr) {
        var len = (String(base || 10).length - String(this).length) + 1;
        return len > 0 ? new Array(len).join(chr || '0') + this : this;
    }
    var d = new Date,
        dformat = [(d.getMonth() + 1).padLeft(),
        d.getDate().padLeft(),
        d.getFullYear()].join('/') + ' ' +
            [d.getHours().padLeft(),
            d.getMinutes().padLeft(),
            d.getSeconds().padLeft()].join(':');
    console.log(dformat)

    var data = {
        datetime: dformat,
        timestamp: timestamp,
        imei: state.deviceInfo.imei,
        brand: state.deviceInfo.brand,
        model: state.deviceInfo.model,
        capacity: state.deviceInfo.capacity ?? null,
        condition: state.gradingInfo.condition,
        gp_price: state.priceInfo.price,
        partner_price: state.deviceInfo.coupon,
        total_price: state.priceInfo.price + state.priceInfo.coupon,
        checkout_type: state.userInformation.payment_method,
        transactionID: state.sessionInfo.uuid,
        partner: flow.partner.code,
        location_name: state.sessionInfo.location.name,
        location_id: state.sessionInfo.location.id,
        full_name: state.userInformation.name,
        email: state.userInformation.email,
        phone_number: state.userInformation.phone,
        rating: state.rating.rating,
        rating_options: state.rating.rating_options
    }

    axios.post(`https://hook.eu1.make.com/a2tpqwm1e0xh11l2z6l6fiusjp31ltjd`, data, { header }).then(res => {
        console.log(res);
        console.log(res.data);
    })
}
import { withTranslation, Trans } from 'react-i18next';
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from '../../utils/context';
import { MainLayout, Top, Center, Bottom } from '../../layouts/MainLayout/MainLayout';
import { MainTitle, MainCard, MainImage, MainSubtitle } from '../../components';

const PriceNotFound = (props) => {
    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        dispatch({ type: "userJourney", payload: 'price-not-found' })
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 800);
    }, [])


    return (
        <>
            <MainLayout
                exit
                nextButton={{
                    text: <Trans i18nKey="price_not_found.next_button" />,
                    to: process.env.PUBLIC_URL + '/exit-page',
                }}
            >
                <Top></Top>
                <Center>
                    <MainCard className='align-items-center justify-content-center'>
                        <MainImage style={{ margin: '0 4vw' }} img='IdentificationFailed/price_not_found.png' height='32vh' />
                        <div style={{ width: '44vw' }}>
                            <MainTitle className="mb-3"><Trans i18nKey="price_not_found.title" /></MainTitle>
                        </div>
                    </MainCard>
                </Center>
                <Bottom></Bottom>
            </MainLayout>
        </>
    );
}

export default withTranslation()(PriceNotFound);
import { withTranslation, Trans } from 'react-i18next';
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from '../../utils/context';
import { MainLayout, Top, Center, Bottom } from '../../layouts/MainLayout/MainLayout';
import { MainImage, MainCard, MainTitle, PaymentButton } from '../../components';
import styled from "styled-components";
import { navigate } from '@reach/router';


const Scrollbar = styled.div`
    height:60vh;
    width: 100%;
    display: flex;
    justify-content:center;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        width: 0px;
    }
`;


const PaymentOptions = (props) => {
    const { t, i18n } = props;
    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    const finalPrice = state.price ? state.price : 730;
    const voucher = 220;

    useEffect(() => {
        dispatch({ type: "userJourney", payload: 'payment-options' })
    }, [])


    return (
        <>
            <MainLayout
                exit
                progress_bar
            >
                <Top></Top>
                <Center>
                    <MainCard height='100%' className='flex-column align-items-center mt-5'>
                        <div style={{ width: '85%' }} className='d-flex justify-content-between my-5' >
                            <MainImage onClick={() => navigate(process.env.PUBLIC_URL + "/offer", { replace: true })} className='my-5 ms-3' img={`PaymentOptions/back_button.svg`} height='5.5vh' />
                            <MainTitle className='my-5'><Trans i18nKey="payment_options.title" /></MainTitle>
                            <div></div>
                        </div>
                        <Scrollbar>
                            <div style={{ width: '85%' }}>
                                {/* <PaymentButton
                                    img='voucher'
                                    title={<span><b className='fw500'>Shopping voucher,</b> please!</span>}
                                    subtitle={`${coupon}€ Promo code included`}
                                    finalPrice={voucher + finalPrice}
                                /> */}
                                <PaymentButton
                                    onClick={() => navigate(process.env.PUBLIC_URL + "/iban", { replace: true })}
                                    img='bank'
                                    title={<Trans i18nKey="payment_options.bank" />}
                                    finalPrice={finalPrice}
                                />
                                <PaymentButton
                                    onClick={() => navigate(process.env.PUBLIC_URL + "/payment-options", { replace: true })}
                                    img='card'
                                    title={<Trans i18nKey="payment_options.card" />}
                                    finalPrice={finalPrice}
                                />
                                {/* <PaymentButton
                                    img='revolut'
                                    title={<span>Straight to <b className='fw500'>my Revolut account</b></span>}
                                    finalPrice={finalPrice}
                                /> */}
                            </div>
                        </Scrollbar>
                    </MainCard>
                </Center>
                <Bottom></Bottom>
            </MainLayout>
        </>
    );
}

export default withTranslation()(PaymentOptions);
import { withTranslation, Trans } from 'react-i18next';
import React from "react";
import styled from "styled-components";
import { Link } from "@reach/router";
import { Icons } from '..';
import { ReactComponent as BackArrow } from './back_arrow.svg';

const LinkStyled = styled(Link)`
    text-decoration:none;
`;
const Button = styled.div`
    width: 18vw;
    max-width 250px;
    height: 12.5vh;
    max-height: 130px;
    background: ${props => ({ theme: { backButton } }) => !props.bg ? (!props.disabled ? backButton.bg : backButton.disabled) : props.bg};
    color: ${props => ({ theme: { backButton } }) => !props.clr ? backButton.clr : props.clr};
    border-radius: ${({ theme: { backButton } }) => backButton.radius};
    font-size: 3.4rem;
    font-weight: 600;
    line-height: 2.8rem;
    display:flex;
    justify-content: center;
    align-items:center;
`;

const BackButton = (props) => {
    const { t, i18n } = props;

    return (
        <LinkStyled replace={true}
            to={(!props.disabled || !props.onClick) ? props.to || "/" : "#"}
            onClick={props.onClick}
        >
            <Button
                disabled={props.disabled}
                bg={props.bg}
                clr={props.clr}
            >
                <span className="me-4 d-inline"><Icons width='3vw' clr={({ theme: { backButton } }) => backButton.clr} > <BackArrow /></Icons></span>
                <span><Trans i18nKey="back" /></span>
            </Button>
        </LinkStyled>

    );
}

export default withTranslation()(BackButton);
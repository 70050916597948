import { withTranslation } from 'react-i18next';
import React, { useContext, useEffect } from "react";
import { AppContext } from '../../utils/context';
import { Drawer } from '../../components';
import { navigate } from '@reach/router';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as sdk from '../../actions/sdk';


const OpenDrawer = (props) => {
    const { t, i18n } = props;
    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    const notify = () => toast(<div className='fs24 text-center'>motion open</div>);

    useEffect(() => {
        let isApiSubscribed = true;
        dispatch({ type: "userJourney", payload: 'open-drawer' })
        sdk.motionOpen().then((response) => {
            if (isApiSubscribed) {
                console.log(response);
                navigate(process.env.PUBLIC_URL + '/timer/insert', { replace: true })
            }
        }).catch((e) => {
            if (process.env.REACT_APP_DEBUG === '1')
                notify();
            // TODO: catch hardware errors
            console.log(e);
        });
        return () => {
            isApiSubscribed = false;
        }
    }, [])

    return (
        <>
            <Drawer open />
        </>
    );
}

export default withTranslation()(OpenDrawer);
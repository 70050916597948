import { withTranslation, Trans } from 'react-i18next';
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from '../../utils/context';
import { MainLayout, Top, Center, BottomLeft } from '../../layouts/MainLayout/MainLayout';
import { MainCard, ConditionTag, Popup, ActionButton, MainImage } from '../../components';
import { navigate } from '@reach/router';
import { flow } from '../../constants';


const Offer = (props) => {
    const { t, i18n } = props;
    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    const brand = state.deviceInfo.brand ? state.deviceInfo.brand : '';
    const model = state.deviceInfo.model ? state.deviceInfo.model : '';
    const color = state.deviceInfo.color ? state.deviceInfo.color : '';
    const capacity = state.deviceInfo.capacity ? state.deviceInfo.capacity : '';
    const finalPrice = state.priceInfo.localPrice ? state.priceInfo.localPrice : '';
    const [show, setShow] = useState(false);
    const [image, setImage] = useState('');
    const [title, setTitle] = useState('');
    const [subtitle, setSubtitle] = useState('');


    useEffect(() => {
        dispatch({ type: "userJourney", payload: 'offer' })
        dispatch({ type: "progress", payload: 'step6' })
        dispatch({ type: "sessionInfo", payload: { offer: 1 } })
    }, [])

    const onNextButton = () => {
        if (state.check.fmi_sim) {
            setShow(true);
            setImage('Offer/fmi_sim.png')
            setTitle(<Trans i18nKey="check_popup.title1" />);
            setSubtitle(<div>1. <Trans i18nKey="check_popup.fmi" /><br />2. <Trans i18nKey="check_popup.sim" /></div>);
        }
        else if (state.check.fmi) {
            setShow(true);
            setImage('Offer/fmi.png')
            setTitle(<Trans i18nKey="check_popup.title2" />);
            setSubtitle(<Trans i18nKey="check_popup.fmi" />);
        }
        else if (state.check.sim) {
            setShow(true);
            setImage('Offer/sim.png')
            setTitle(<Trans i18nKey="check_popup.title2" />);
            setSubtitle(<Trans i18nKey="check_popup.sim" />);
        }
        else {
            setTimeout(() => {
                navigate(process.env.PUBLIC_URL + flow.offer.next_button.payment, { replace: true })
            }, 100);
        }
    }

    return (
        <>
            <MainLayout
                progress_bar
                exit
                nextButton={{
                    text: <Trans i18nKey="offer.next_button" />,
                    to: '#',
                    onClick: () => onNextButton()
                }}
            >
                <Top></Top>
                <Center>
                    <MainCard className='d-flex align-items-center' large>
                        <div style={{ width: '100%' }}>
                            <div className='d-flex align-items-center mb-5' style={{ width: '100%' }}>
                                <MainImage className='me-5' img='Offer/offer.png' height='' />
                                <div style={{ width: '100%' }}>
                                    <div className='fs30 fw600 lh48 mb-5'>{brand} {model} <span className='fw400'> {brand === 'Apple' && capacity}</span></div>
                                    <div><ConditionTag offer /></div>
                                    <div style={{ width: '100%', borderBottom: '2px solid #E6E6E6', margin: '50px 0 40px 0' }}></div>
                                    <div className='d-flex justify-content-between'>
                                        <div className='fs30 lh48'>
                                            <span style={{ fontSize: '6.4rem' }} className='fw600 lh28'>{flow.coin.front}{finalPrice}{flow.coin.back}<span className='fs28 fw500'><Trans i18nKey="offer.payment_method" /></span></span>
                                            <div className='fs16 lh28 fontGray'><Trans i18nKey="offer.prices_change" /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ width: '100%', height: '10.5vh', background: '#FBF7F4', borderRadius: '0.8rem', padding: '20px 20px 30px' }}>
                                <div className='fs16 mb-3' style={{ color: '#B8B5B3' }}><Trans i18nKey="offer.trading" /></div>
                                <div className='d-flex align-items-center fs16'>
                                    <div className='d-flex align-items-center'>
                                        <MainImage className='me-3' img='Offer/Tree.svg' />
                                        <div><Trans i18nKey="offer.tree" /></div>
                                    </div>
                                    <div style={{ margin: '0 80px' }} className='d-flex align-items-center'>
                                        <MainImage className='me-3' img='Offer/device.svg' />
                                        <div><Trans i18nKey="offer.device" /></div>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <MainImage className='me-3' img='Offer/leaf.svg' />
                                        <div><Trans i18nKey="offer.leaf" /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </MainCard>
                </Center>
                <BottomLeft>
                    {/* <div style={{ position: 'relative', bottom: '7%', left: '4%' }}>
                        <img style={{ borderRadius: '1.6rem', maxWidth: '300px' }} src={"data:image/jpeg;base64," + state.deviceInfo.photos[0]} alt="live_feed_image" />
                    </div> */}
                </BottomLeft>
            </MainLayout>
            <Popup show={show} onHide={() => setShow(false)}>
                <div className='center flex-column'>
                    <div className='center'>
                        <MainImage style={{ marginRight: '8vw' }} img={image} />
                        <div className='d-flex flex-column my-5 pb-5'>
                            <div className='fs38 lh48 fw600 mb-5'>{title}</div>
                            <div className='fs30 lh40  mb-5 '>{subtitle}</div>
                        </div>
                    </div>
                    <div className='d-flex'>
                        <ActionButton className='mx-4' to={process.env.PUBLIC_URL + flow.offer.next_button.fmi_sim} width='27vw'><Trans i18nKey="next_button" /></ActionButton>
                    </div>
                </div>
            </Popup>
        </>
    );
}

export default withTranslation()(Offer);
import { ThemeProvider } from "styled-components";

const themes = {
    "greenpanda": {
        primaryButton: {
            bg: '#1D1D1D',
            disabled: '#808080',
            clr: '#FFFFFF',
            radius: '65px',
            box_shadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.25), inset 0px 4px 16px 0px rgba(255, 255, 255, 0.50)'
        },
        secondaryButton: {
            bg: '#FFFFFF',
            clr: '#1A1A1A',
            radius: '65px',
            box_shadow: '0px 8px 24px 0px rgba(0, 0, 0, 0.1), inset 0px -4px 4px 0px rgba(0, 0, 0, 0.1)'
        },
        backButton: {
            bg: '#F7F7F7',
            clr: '#1A1A1A',
            radius: '65px'
        },
        colors: {
            primary: '#1D1D1D',
            light_primary: '#F2F2F2',
            secondary: '#FFFFFF',
            third: '#BBFFCC',
            light_third: '#DBFFE4',
            danger: '#FF4B55',
            light_danger: '#F8E6E7',
            fonts: '#1A1A1A'
        },
        radius: {
            button: '100px',
            icon: '50%'
        },
        homeLogo: '',
        logo: 'url(/pandas_logo.svg)',
        imagePath: 'images/GreenPanda'
    },
    "orangepl": {
        primaryButton: {
            bg: '#1A1A1A',
            disabled: '#808080',
            clr: '#FFFFFF',
            radius: '65px',
        },
        secondaryButton: {
            bg: '#FFFFFF',
            clr: '#1A1A1A',
            radius: '65px',
        },
        backButton: {
            bg: '#F7F7F7',
            clr: '#1A1A1A',
            radius: '65px'
        },
        colors: {
            primary: '#1D1D1D',
            light_primary: '#F2F2F2',
            secondary: '#FFFFFF',
            third: '#BBFFCC',
            light_third: '#DBFFE4',
            danger: '#FF4B55',
            light_danger: '#F8E6E7',
            fonts: '#1A1A1A'
        },
        radius: {
            button: '100px',
            icon: '50%'
        },
        homeLogo: '',
        logo: 'url(/pandas_logo.svg)',
        imagePath: 'images/GreenPanda'
    },
    "ismash": {
        colors: {
            primary: '#FF138C',
            light: '#FEE9F7',
            secondary: '#290B38',
            danger: 'black',
            disabled: '#c8c8c8',
            fonts: '#290B38'
        },
        radius: {
            button: '100px',
            icon: '50%'
        },
        logo: 'url(/logo_ismash.png)',
        imagePath: 'images/GreenPanda'
    },
    "public": {
        colors: {
            actionButton: {
                primary: 'black',
                secondary: 'orange',
                disabled: '#c8c8c8'
            },
            exitButton: {
                primary: '#2A3845',
                secondary: '#41D900',
                disabled: '#c8c8c8'
            },
            backButton: {
                primary: '#2A3845',
                secondary: '#41D900',
                disabled: '#c8c8c8'
            },
            primary: 'orange',
            secondary: 'black',
            danger: 'red',
            disabled: '#c8c8c8',
            fonts: '#263846'
        },
        radius: {
            button: '100px',
            icon: '50%'
        },
        logo: 'url(/logo.svg)',
    },
}

const Theme = ({ children }) => (
    <ThemeProvider theme={themes[process.env.REACT_APP_THEME || 'greenpanda']}>{children}</ThemeProvider>
);
export default Theme;
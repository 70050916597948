import { withTranslation, Trans } from 'react-i18next';
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from '../../utils/context';
import { MainLayout, Top, Center, Bottom } from '../../layouts/MainLayout/MainLayout';
import { MainCard, Input, CheckBox, PrivacyPolicy, PrivacyPolicyOrangepl } from '../../components';
import axios from 'axios';


const UserInformation = (props) => {
    const flow = require(`../../flow/${process.env.REACT_APP_FLOW}`);
    const { t, i18n } = props;
    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;

    useEffect(() => {
        dispatch({ type: "userJourney", payload: 'user-information' })
        dispatch({ type: "progress", payload: 'step7' })
        if (flow.user_information.voucher) {
            axios.get(`https://session-service.greenpanda.io/voucher/code/${flow.partner.code}/${state.deviceInfo.imei}`)
                .then((res) => {
                    console.log(res.data)
                    dispatch({
                        type: "input",
                        payload: {
                            payment_value: res.data.code,
                            payment_method: "voucher"
                        }
                    })
                })
        }
    }, [])

    const [email, setEmail] = useState();
    const [name, setName] = useState();
    const [phone, setPhone] = useState();
    const [show, setShow] = useState(false);
    const [disabled, setDisabled] = useState(true);


    useEffect(() => {
        if (state.userInformation.email) {
            if (/^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/.test(state.userInformation.email))
                setEmail(true);
            else
                setEmail(false);
        }
    }, [state.userInformation.email]);

    useEffect(() => {
        if (state.userInformation.name) {
            if (state.userInformation.name.length > 0)
                setName(true);
            else
                setName(false);
        }
    }, [state.userInformation.name]);

    useEffect(() => {
        if (state.userInformation.phone) {
            if (state.userInformation.phone.match(/\d{10,15}/))
                setPhone(true)
            else
                setPhone(false)
        }
    }, [state.userInformation.phone]);

    useEffect(() => {
        if (state.checkbox)
            if (state.checkbox.privacy_policy)
                setDisabled(false)
            else
                setDisabled(true)
    }, [state.checkbox])


    return (
        <>
            <MainLayout
                progress_bar
                exit
                nextButton={{
                    text: <Trans i18nKey="next_button" />,
                    to: process.env.PUBLIC_URL + '/confirm-payment',
                    disabled: (!email || !name || !phone || disabled)
                }}
                backButton={{
                    to: process.env.PUBLIC_URL + flow.user_information.back_button
                }}
            >
                <Top></Top>
                <Center>
                    <MainCard className='flex-column center' large>
                        <div className='fs30 fw600 lh36 text-center mb-5 pb-5'><Trans i18nKey="user_information.title" /></div>
                        <div>
                            <Input label={<Trans i18nKey="user_information.full_name" />} field="name" placeholder={t('user_information.enter_fullname')} />
                            <div className="my-5"><Input label='EMAIL' field="email" placeholder={t('user_information.enter_email')} /></div>
                            <Input label={<Trans i18nKey="user_information.phone" />} field="phone" placeholder={t('user_information.enter_phone')} />
                            <div className='center mt-5 pt-3'>
                                <CheckBox field='privacy_policy' />
                                <div style={{ width: '100%' }} className='fs20 fw600 text-start ms-4'>
                                    <Trans i18nKey="user_information.agree" /> <u onClick={() => setShow(true)}><Trans i18nKey="user_information.privacy_policy" /></u>
                                </div>
                            </div>
                        </div>
                    </MainCard>
                </Center>
                <Bottom></Bottom>
            </MainLayout>
            {i18n.language === 'en' && <PrivacyPolicy show={show} onHide={() => setShow(false)} />}
            {i18n.language === 'pl' && <PrivacyPolicyOrangepl show={show} onHide={() => setShow(false)} />}
        </>
    );
}

export default withTranslation()(UserInformation);
import { Trans } from 'react-i18next';
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from '../../utils/context';
import { MainLayout, Top, Center, Bottom } from '../../layouts/MainLayout/MainLayout';
import { MainImage, MainCard, MainTitle, ActionButton, Popup, InfoButton, EmailPopup, MainVideo } from '../../components';
import { navigate } from '@reach/router';
import { flow } from '../../constants';


const Screen = (props) => {
    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;

    useEffect(() => {
        dispatch({ type: "userJourney", payload: 'screen' })
        dispatch({ type: "progress", payload: 'step4' })
    }, []);


    return (
        <>
            <MainLayout
                progress_bar
                exit
                nextButton={{
                    text: <Trans i18nKey="next_button" />,
                    to: process.env.PUBLIC_URL + '/open-drawer'
                }}
                backButton={{ to: process.env.PUBLIC_URL + '/timer/accessories' }}
            >
                <Top></Top>
                <Center>
                    <MainCard className='center'>
                        <MainVideo style={{ marginRight: '5vw' }} video='Screen/screen.mp4' />
                        <div>
                            <MainTitle style={{ width: '45vw' }} className='mb-5'><Trans i18nKey="screen" /></MainTitle>
                        </div>
                    </MainCard>
                </Center>
                <Bottom></Bottom>
            </MainLayout>
        </>
    );
}

export default Screen;
import { withTranslation, Trans } from 'react-i18next';
import React, { useContext, useState, useEffect } from "react";
import { AppContext } from '../../utils/context';
import { navigate } from '@reach/router';
import { v4 as uuidv4 } from 'uuid';
import styled from "styled-components";
import { MainImage, MainVideo, Icons, SelectLanguage, RecyclingPopup } from '../../components';
import { ReactComponent as NextArrow } from '../../images/next_arrow.svg';
import * as sdk from '../../actions/sdk';
import { flow } from '../../constants';


const Button = styled.div`
    width: 28.5vw;;
    max-width 420px;
    height: 10vh;
    max-height: 130px;
    padding: 0 3vw;
    background: ${props => ({ theme: { primaryButton } }) => !props.disabled ? primaryButton.bg : primaryButton.disabled};
    color: ${({ theme: { primaryButton } }) => primaryButton.clr};
    border-radius: ${({ theme: { primaryButton } }) => primaryButton.radius};
    font-size: 3rem;
    font-weight: 600;
    line-height: 2.8rem;
    display:flex;
    justify-content: center;
    align-items:center;
`;

const Home = (props) => {
    const { t, i18n } = props;
    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;

    const [loading, setLoading] = useState(true);
    const [location, setLocation] = useState(false);
    const [showRecycling, setShowRecycling] = useState(false);
    const [showLanguage, setShowLanguage] = useState(false);
    let currentDate = new Date()
    const timestamp = currentDate.getTime();

    useEffect(() => {
        dispatch({ type: "userJourney", payload: 'home' })
        console.log("~~~~~~~~~~~~~~~~~~~~~~");
        console.log("INIT NEW SESSION");
        var uuid = uuidv4();
        console.log("UUID", uuid)
        sdk.Location().then((res) => {
            console.log(res.data[0])
            dispatch({ type: "sessionInfo", payload: { location: res.data[0] } })
            setLocation(true);
        })
        sdk.motionMotion().then((res) => {
            console.log(res)
            setLoading(false);
            dispatch({ type: "sessionInfo", payload: { uuid: uuid } })
        })
            .catch((e) => {
                // TODO: catch hardware errors
                console.log(e);
            });
        dispatch({ type: "init", payload: { uuid: uuid, path: [] } })
    }, []);


    const start = () => {
        console.log("Waiting to start");
        if (!loading) {
            console.log("STARTING SESSION");
            dispatch({
                type: "sessionInfo",
                payload: {
                    flow: flow.home.lets_start,
                    start: timestamp
                }
            })
            console.log("Started");
            navigate(process.env.PUBLIC_URL + '/timer/turn-on-device', { replace: true })
        }
    };
    const DetailedDiagnostics = () => {
        console.log("Waiting to start");
        if (!loading) {
            console.log("STARTING SESSION");
            dispatch({
                type: "sessionInfo",
                payload: {
                    flow: flow.home.detailed_diagnostics_button,
                    start: timestamp
                }
            })
            navigate(process.env.PUBLIC_URL + '/timer/turn-on-device', { replace: true })
        }
    };

    const firstLanguage = () => {
        i18n.changeLanguage(flow.languages.first);
        setShowLanguage(false);
        dispatch({ type: "sessionInfo", payload: { language: i18n.language } })
    }
    const secondLanguage = () => {
        i18n.changeLanguage(flow.languages.second);
        setShowLanguage(false);
        dispatch({ type: "sessionInfo", payload: { language: i18n.language } })
    }

    return (
        <>
            {!location &&
                <div className='center' style={{ width: '100%', height: '100vh' }}>
                    <div className="loader">Loading...</div>
                </div>
            }
            {location && <div onContextMenu={(e) => e.preventDefault()} style={{ height: '100vh', width: '100%' }}>
                <div className='d-flex flex-column justify-content-between' style={{ height: flow.home.bottom_button ? '75%' : '100%', width: '100%', background: '#FFFFFF', padding: '5vh 0 0 5vw' }}>
                    <div className='d-flex justify-content-between align-items-start' style={{ paddingRight: '5vw' }}>
                        <MainImage className="text-start" img='Logos/logo.svg' height='' />
                        <div className='center'>
                            {flow.home.recycling &&
                                <div onClick={() => setShowRecycling(true)} className='center px-5' style={{ background: '#F2F2F2', borderRadius: '2.8rem', minHeight: '6vh' }}>
                                    <MainImage className='mt-1' img='Home/recycle.png' height='24px' />
                                    <div className='fs20 ms-4'><Trans i18nKey="home.recycle" /></div>
                                </div>}
                            {flow.home.tutorial && <MainImage onClick={() => navigate(loading ? '#' : process.env.PUBLIC_URL + '/timer/tutorial', { replace: true })} className='mx-5' img='Home/how_to_button.svg' height='6vh' />}
                            {flow.home.languages && <div onClick={() => setShowLanguage(true)} className='center'>
                                <MainImage img={i18n.language === flow.languages.first ? 'Languages/first.svg' : 'Languages/second.svg'} height='' />
                                <MainImage className='ms-4' img='Home/down_arrow.svg' height='' />
                            </div>}
                        </div>
                    </div>
                    <div style={{ width: '100%', height: '100%' }} className='d-flex justify-content-end'>
                        <div className={flow.home.bottom_button ? 'd-flex align-items-end' : 'd-flex align-items-center'}>
                            <div className='center'>
                                <div style={!flow.home.bottom_button ? { position: 'absolute', left: '4vw' } : {}} className='ps-5 pb-5'>
                                    <div style={{ width: '46vw' }} className='fontThicker fs72 lh72 fw800 mb-5'><Trans i18nKey="home.title" /></div>
                                    <div style={{ marginBottom: '8vh', width: '40vw' }} className='fs20 lh28'><Trans i18nKey="home.subtitle" /></div>
                                    <Button disabled={loading} onClick={() => start()}>
                                        <Trans i18nKey="home.let's start" />
                                        <Icons className='ms-4' width='3vw' clr={({ theme: { primaryButton } }) => primaryButton.clr} > <NextArrow /></Icons>
                                    </Button>
                                </div>
                                {!flow.home.bottom_button && <MainVideo width='43vw' loop video='Home/hand_1.mp4' />}
                                {flow.home.bottom_button && <MainVideo width='' loop video='Home/hand.mp4' />}
                            </div>
                        </div>
                    </div>
                </div>
                {flow.home.detailed_diagnostics &&
                    <div className='center' style={{ height: '25%', width: '100%', background: '#EDEDED' }}>
                        <div onClick={() => DetailedDiagnostics()} className='d-flex align-items-center justify-content-between' style={{ width: '94vw', height: '18vh', background: '#F7F7F7', borderRadius: '1.6rem', padding: '0 4vw' }}>
                            <div className='center'>
                                <MainImage img='Home/detailed_diagnostics.png' height='' />
                                <div className='mx-5 ps-4'>
                                    <div className='fs38 fw600 lh48 mb-2'><Trans i18nKey="home.detailed diagnostics" /></div>
                                    <div className='fs20 lh28 fontGray'><Trans i18nKey="home.full device check" /></div>
                                </div>
                            </div>
                            <MainImage img='Home/next_arrow.png' height='5vh' />
                        </div>
                    </div>}
                {flow.home.pricing_app &&
                    <div className='center' style={{ height: '25%', width: '100%', background: '#EDEDED' }}>
                        <div className='d-flex align-items-center justify-content-between' style={{ width: '94vw', height: '18vh', background: '#F7F7F7', borderRadius: '1.6rem', padding: '0 4vw' }}>
                            <div className='center'>
                                <MainImage img='Home/price_estimation.png' height='' />
                                <div className='mx-5 ps-4'>
                                    <div className='fs38 fw600 lh48 mb-2'><Trans i18nKey="home.pricing_title" /></div>
                                    <div className='fs20 lh28 fontGray'><Trans i18nKey="home.pricing_subtitle" /></div>
                                </div>
                            </div>
                            <MainImage img='Home/next_arrow.png' height='5vh' />
                        </div>
                    </div>}
                <SelectLanguage show={showLanguage} onHide={() => setShowLanguage(false)} firstLanguage={() => firstLanguage()} secondLanguage={() => secondLanguage()} />
                <RecyclingPopup show={showRecycling} onHide={() => setShowRecycling(false)} />
            </div>}
        </>
    );
}

export default withTranslation()(Home);
import { withTranslation, Trans } from 'react-i18next';
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from '../../utils/context';
import { MainLayout, Top, Center, Bottom } from '../../layouts/MainLayout/MainLayout';
import { ActionButton, InfoButton, MainImage, RateExperience, } from '../../components';
import Icon from '../../components/InfoButton/images/newspaper.svg'
import { UploadData } from '../../actions/uploadData';
import { flow } from '../../constants';
import * as sdk from '../../actions/sdk';
import { Webhook } from '../../actions/webhook';


const ThankYouPage = (props) => {
    const { t, i18n } = props;
    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    const [show, setShow] = useState(false);

    useEffect(() => {
        UploadData(state);
        Webhook(state);
        dispatch({ type: "userJourney", payload: 'thank-you-page' })
    }, [])

    const onSubmit = () => {
        Webhook(state)
        setShow(false)
    }

    return (
        <>
            <MainLayout noLogo >
                <Top></Top>
                <Center>
                    <div className='d-flex flex-column align-items-center justify-content-center'>
                        <MainImage style={{ margin: '0 6vw' }} img='Inspection/inspection.png' height='29vh' />
                        <div className='fs48 lh60 fw600 text-center mt-5 pt-5'><Trans i18nKey="thank_you_page.title" />🙌</div>
                        <div className='fs38 lh60 text-center mb-5 pb-5'><Trans i18nKey="thank_you_page.subtitle" />🌱</div>
                        <ActionButton width='26vw' onClick={() => setShow(true)} className='mb-5 '><Trans i18nKey="thank_you_page.rate_experience" /></ActionButton>
                        {flow.print && <InfoButton onClick={() => sdk.printerPrint(state)} icon={Icon}><Trans i18nKey="thank_you_page.print_voucher" /></InfoButton>}
                    </div>
                </Center>
                <Bottom>
                </Bottom>
            </MainLayout>
            <RateExperience show={show} onHide={() => setShow(false)} onSubmit={() => onSubmit()} />
        </>
    );
}

export default withTranslation()(ThankYouPage);
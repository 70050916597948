import { withTranslation, Trans } from 'react-i18next';
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from '../../utils/context';
import { MainLayout, Top, Center, Bottom } from '../../layouts/MainLayout/MainLayout';
import { MainImage, MainCard, MainTitle, Popup, ActionButton, EmailPopup } from '../../components';
import { navigate } from '@reach/router';

const Start = (props) => {
    const { t, i18n } = props;
    const appContext = useContext(AppContext);
    const { state, dispatch } = appContext;
    const [showBattery, setShowBattery] = useState(false);
    const [showBiometrics, setShowBiometrics] = useState(false);
    const [showEmail, setShowEmail] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    useEffect(() => {
        dispatch({ type: "userJourney", payload: 'start' })
    }, [])

    const SubmitEmail = () => {
        setShowEmail(false);
        setShowSuccess(true);
        setTimeout(() => {
            navigate(process.env.PUBLIC_URL + '/home', { replace: true })
        }, 4000);
    }


    return (
        <>
            <MainLayout
                exit
                nextButton={{
                    text: <Trans i18nKey="start.next_button" />,
                    to: process.env.REACT_APP_SIM === '1' ? process.env.PUBLIC_URL + '/sim-instructions' : process.env.PUBLIC_URL + '/wifi-qr',
                }}
                backButton={{ to: process.env.PUBLIC_URL + '/' }}
            >
                <Top></Top>
                <Center>
                    <MainCard large className='flex flex-column justify-content-start align-items-start' style={{ padding: '60px' }}>
                        <div className='fs72 lh72 fw500 mb-5 pb-5'><Trans i18nKey="start.title" /></div>
                        <div className='d-flex align-items-center'>
                            <div style={{ width: '10%' }}><MainImage className='pb-5' img='Start/power.svg' height='' /></div>
                            <div style={{ width: '90%', borderBottom: '2px solid #E6E6E6' }} className='d-flex align-items-center pb-5'>
                                <div style={{ width: '90%' }} className='fs30 lh48'><Trans i18nKey="start.turn_on" /></div>
                                <div className='text-end' style={{ width: '10%' }}><MainImage onClick={() => setShowBattery(true)} img='Start/info.svg' /></div>
                            </div>
                        </div>
                        <div className='d-flex align-items-center'>
                            <div style={{ width: '10%' }}><MainImage className='' img='Start/fingerprint.svg' height='50px' /></div>
                            <div style={{ width: '90%' }} className='d-flex align-items-center pt-5'>
                                <div style={{ width: '90%' }}>
                                    <div className='fs30 lh48'><Trans i18nKey="start.biometrics" /></div>
                                    <div className='fs30 lh48 fontGray'><Trans i18nKey="start.note" /></div>
                                </div>
                                <div className='text-end' style={{ width: '10%' }}><MainImage onClick={() => setShowBiometrics(true)} img='Start/info.svg' /></div>
                            </div>
                        </div>
                    </MainCard>
                </Center>
                <Bottom></Bottom>
            </MainLayout>
            <Popup show={showBattery} onHide={() => setShowBattery(false)}>
                <div className='d-flex flex-column align-items-center'>
                    <MainTitle className='text-center mt-4'><Trans i18nKey="start.popup_turn_on_title" /></MainTitle>
                    <div className='d-flex align-items-center my-5 pb-5'>
                        <MainImage img='Start/battery.png' height='13vh' />
                        <div>
                            <div className='ms-5 fs20 lh26 mb-4'><Trans i18nKey="start.popup_turn_on_subtitle1" /></div>
                            <div className='ms-5 fs20 lh26'><Trans i18nKey="start.popup_turn_on_subtitle2" /></div>
                        </div>
                    </div>
                    <div className='d-flex'>
                        <ActionButton className='mx-4' onClick={() => setShowBattery(false)} to='#' secondary width='26vw'><Trans i18nKey="start.popup_turn_on_done" /></ActionButton>
                        <ActionButton className='mx-4' onClick={() => { setShowBattery(false); setShowEmail(true); }} to='#' width='27vw'><Trans i18nKey="start.popup_turn_on_reminder" /></ActionButton>
                    </div>
                </div>
            </Popup>
            <Popup show={showBiometrics} onHide={() => setShowBiometrics(false)}>
                <div className='d-flex flex-column align-items-center'>
                    <div className='d-flex align-items-center my-5 pb-5'>
                        <MainImage className='me-5 pe-5' img='Start/biometrics.png' height='16vh' />
                        <div>
                            <div className='fs30 lh36 fw600 my-4'><Trans i18nKey="start.popup_biometrics_subtitle1" /></div>
                            <div className='fs20 lh26'><Trans i18nKey="start.popup_biometrics_subtitle2" /></div>
                        </div>
                    </div>
                    <div className='d-flex mt-5'>
                        <ActionButton className='mx-4' onClick={() => setShowBiometrics(false)} to='#' width='27vw'><Trans i18nKey="start.popup_biometrics_done" /></ActionButton>
                    </div>
                </div>
            </Popup>
            <EmailPopup show={showEmail} onHide={() => setShowEmail(false)} submit={() => SubmitEmail()} success={showSuccess} onHideSuccess={() => setShowSuccess(false)} />
        </>
    );
}

export default withTranslation()(Start);